  .cards{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;   
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 29px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 10%;
   margin-right: 15%;
  }   
  .card1{
   width: 190px;
   height: 254px;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card2-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}

@media screen and (max-width: 1150px) {

   
  .cards{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;
        
        
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 25px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 20%;
   margin-right: 20%;
  }   

  .card1{
   width: 200px;
   height: 235px;
   
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card2-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 39%;
    margin-top: -2%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

@media screen and (max-width: 800px) {

      
  .cards{
   z-index: 1;
   width: 100%;
   height: 120rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
   display: grid;
   width: 100%;
   height: 70rem;
   margin-left: 0%;
   margin-top: 30px;
   
        
        
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 23px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: -5%;
   margin-right: 5%;
  }   

  .card1{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 145px;
     }
     .card2-img{
        width: 90%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 32%;
    margin-top: 80%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

}
@media screen and (max-width: 650px) {

   .cards-section{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px;           
     }
     .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 30%;
       margin-top: 90%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }
     .cards{
      height: 115rem;
     }
     .card1{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
}

@media screen and (max-width: 450px) {

   .cards{
      z-index: 1;
      width: 100%;
      height: 90rem;
      margin-top: 95px;
      margin-right: auto;
      margin-left: auto;
      align-items: center;
      justify-content: center;
      background-color: #1c2237;
   
     }
     .cards-section{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px; 
     }
     .full-items{
      z-index: 1;
      width: 100%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 50px;
      padding-left: 50px;
   
     }
     .head-card-module{
         
      text-align: center;
    font-size: 18px;
    padding-top: 15%;
    color: #fff;
    font-weight: 600;
    margin-left: -15%;
    margin-right: -15%;
     }   
   
     .card1{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 18%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
        .card2-name{
           font-size: 21px;
           font-weight: 600;
           text-align: center;
           font-family: 'Poppins', sans-serif;
           color: black;
           margin-top: 115px;
        }
        .card2-img{
           width: 90%;
           margin-top: 15%;
           margin-bottom: -40%;
        }
   .card{    
      width: 20%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
   }
   .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 20%;
       margin-top: 40%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }

}

  .second-button:after {
   content: " ";
   width: 0%;
   height: 100%;
   background: #25ce4a;
   position: absolute;
   transition: all 0.4s ease-in-out;
   right: 0;
  }
  
  .second-button:hover::after {
   right: auto;
   left: 0;
   width: 100%;
  }
  
  .second-button span {
   text-align: center;
   text-decoration: none;
   width: 100%;
   padding: 15px 30px;
   color: #fff;
   font-size: 100%;
   font-weight: 600;
   letter-spacing: 0.1rem;
   z-index: 20;
   transition: all 0.3s ease-in-out;
  }
  
  .second-button:hover span {
   color: #1c2237;
   animation: scaleUp 0.3s ease-in-out;
  }
  
  @keyframes scaleUp {
   0% {
    transform: scale(1);
   }
  
   50% {
    transform: scale(0.95);
   }
  
   100% {
    transform: scale(1);
   }
  }

