.services-list{
    margin-bottom: -12px;
    margin-top: 58px;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }

  .top-img-sec{
    align-items: center;
    margin-left: 23%;
    margin-top: -5%;
    margin-bottom: -5%;
    width: 7%;
  }
  
  .head-module{
    text-align: center;
    font-size: 45px;
    margin-bottom: 30px;
    color: #1c2237;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  
  }

  .head-sub-module{
    text-align: center;
    font-size: 33px;
    color: #1c2237;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-top: 16%;
    margin-bottom: 0%;
  }

  .services{
    margin-left: 20px;
  }
  
  
  .service{

    font-size: 20px;
    margin-left: 60%;
    margin-right: 0%;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    margin-top: 25px;
    margin-bottom: 53px
  
  }
 .service-img{
  
      margin-top: 20px;
      width: 75px;
      height: 56px;
      margin-left: -18%;
      margin-bottom: -15%;
  
    }
    .service-head{
      font-size: 26px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-bottom: 10px;
      font-weight: 600;
     
  
    }
    .service-description{
  
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-bottom: 40px;
      font-weight: 400;
      margin-left: 1%;
      margin-right: 25%;
      margin-top: 10px;
  
    }
    
    .srv-imge{
      width: 35%;
      margin-top: -47%;
      margin-left: 10%;
    }

    @media screen and (max-width: 1200px) {
  

        .service{
          font-size: 5px;
          margin-left: 61%;
          margin-right: -4%;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-top: 25px;
          margin-bottom: 53px;
      
        }
        
        .srv-imge{
          width: 40%;
          margin-top: -60%;
          margin-left: 10%;
        }
        .top-img-sec{
          align-items: center;
          margin-left: 20%;
          margin-top: -5%;
          margin-bottom: -5%;
          width: 7%;
        
        }
    
  }
  
  @media screen and (max-width: 1000px) {
  
      
      
      
    .service{
      font-size: 5px;
      margin-left: 55%;
      margin-right: -5%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    
    .srv-imge{
      width: 40%;
      margin-top: -70%;
      margin-left: 6%;
    }
    .top-img-sec{
      align-items: center;
      margin-left: 12%;
      margin-top: -5%;
      margin-bottom: -5%;
      width: 10%;
    
    }
  
  }

  @media screen and (max-width: 850px) {
    
      
    .service{
      font-size: 5px;
      margin-left: 55%;
      margin-right: -5%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    
    .srv-imge{
      width: 50%;
      margin-top: -220%;
      margin-left: 25%;
    }
    .services{
      margin-left: -25%;
      margin-right: 17%;
      margin-top: 62%;
    }
    .top-img-sec{
      align-items: center;
      margin-left: 6%;
      margin-top: -5%;
      margin-bottom: -6%;
      width: 10%;
    
    }
  
  }

  @media screen and (max-width: 760px) {
   
    .head-module{
      text-align: center;
      font-size: 40px;
      margin-left: -20%;
      margin-bottom: 30px;
      color: #1c2237;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    
    }
    
    .service{
      font-size: 5px;
      margin-left: 44%;
      margin-right: -8%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    .top-img-sec{
      align-items: center;
      margin-left: 0%;
      margin-top: 0%;
      margin-bottom: -5%;
      width: 10%;
    
    }
    
    .srv-imge{
      width: 52%;
      margin-top: -230%;
      margin-left: 12%;
    }
    .services{
      margin-left: -25%;
      margin-right: 17%;
      margin-top: 60%;
    }

   } 

   @media screen and (max-width: 680px) {
  
      .srv-imge{
        width: 55%;
        margin-top: -268%;
        margin-left: 12%;
      }
      .services{
        margin-left: -25%;
        margin-right: 17%;
        margin-top: 70%;
      }
      .head-module{
        margin-left: -15%;
      }
      .top-img-sec{
        align-items: center;
        margin-left: -5%;
        margin-top: -5%;
        margin-bottom: -7%;
        width: 12%;
      
      }
  
  
    }

    @media screen and (max-width: 600px) {
  
      .srv-imge{
        width: 65%;
        margin-top: -285%;
        margin-left: 10%;
      }
      .services{
        margin-left: -25%;
        margin-right: 17%;
        margin-top: 77%;
      }
      .head-module{
        margin-left: -10%;
      }
      .service{
        font-size: 5px;
        margin-left: 45%;
        margin-right: -23%;
        font-family: 'Poppins', sans-serif;
        color: #1c2237;
        margin-top: 25px;
        margin-bottom: 53px;
    
      }
      .top-img-sec{
        align-items: center;
        margin-left: -5%;
        margin-top: -5%;
        margin-bottom: -7%;
        width: 12%;
      
      }
  
    }

    @media screen and (max-width: 560px) {
  
        .srv-imge{
          width: 60%;
          margin-top: -315%;
          margin-left: 15%;
        }
        .services{
          margin-left: -25%;
          margin-right: 17%;
          margin-top: 77%;
        }
        .head-module{
          font-size: 35px;
          margin-left: -5%;
        }
        .top-img-sec{
          align-items: center;
          margin-left: -7%;
          margin-top: -7%;
          margin-bottom: -7%;
          width: 15%;
        
        }
      }
      @media screen and (max-width: 432px) {
        .srv-imge {
          width: 60%;
          margin-top: -400%;
          margin-left: 15%;
      }
      }

      @media screen and (max-width: 420px) {
        .services-list{
          width: 65%;
        }
  
        .srv-imge{
          width: 95%;
          margin-top: -490%;
          margin-left: 0%;
        }
        .services{
          margin-left: -40%;
          margin-right: 8%;
          margin-top: 100%;
        }
        .head-module{
          font-size: 30px;
          margin-left: -30%;
          margin-right: -20%;
        }
        
        .service{
          font-size: 5px;
         margin-left: 52%;
         margin-right: -25%;
         font-family: 'Poppins', sans-serif;
          color: #1c2237;
         margin-top: 25px;
         margin-bottom: 53px;
      
        }
        .top-img-sec{
          align-items: center;
          margin-left: -38%;
          margin-top: -10%;
          margin-bottom: -11%;
          width: 25%;
        
        }
        .service-head{
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-bottom: 10px;
          font-weight: 600;
         margin-left: -5%;
      
        }
        .service-description{
  
          font-size: 10px;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-bottom: 40px;
          font-weight: 400;
          margin-left: -5%;
          margin-right: 10%;
          margin-top: 10px;
      
        }
        .service-img{
  
          margin-top: 22px;
          width: 50px;
          height: 50px;
          margin-left: -35%;
          margin-bottom: -30%;
      
        }
      }
      @media screen and (max-width: 380px) {
        .srv-imge {
          width: 95%;
          margin-top: -550%;
          margin-left: 0%;
        }
      }