.sub-section{
    
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  .service-main-head{
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 0%;
    margin-top: -46%;
    margin-bottom: 0%;
      .green-text{
        color: #25ce4a;
      }
    }
    .bg-view{
     
       
      width: 100%;
      height: 100%;
      margin-left: 0%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -40px;
      max-width: 100%;
      height: auto;
  
     
     
    }
    .full-section {
      width: 100%;
      margin-top: 0%;
      margin-bottom: 30%;
  }
  
  
  .button {
      position: relative;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      padding-block: 0.5rem;
      padding-inline: 1.25rem;
      background-color: #25ce4a;
      border-radius: 9999px;
      display: flex;
      align-items: center;
      height: 45px;
      width: 190px;
      justify-content: center;
      color: #ffff;
      grid-gap: 10px;
      grid-gap: 10px;
      gap: 10px;
      font-weight: bold;
      border: 3px solid #ffffff4d;
      outline: none;
      overflow: hidden;
      font-size: 15px;
      margin-left: 10%;
      margin-top: 70px;
    }
    
    .icon {
      width: 24px;
      height: 24px;
      transition: all 0.3s ease-in-out;
    }
    
    .button:hover {
      transform: scale(1.05);
      border-color: #fff9;
    }
    
    .button:hover .icon {
      transform: translate(4px);
    }
    
    .button:hover::before {
      animation: shine 1.5s ease-out infinite;
    }
    
    .button::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -100px;
      opacity: 0.6;
    }
    
    @keyframes shine {
      0% {
        left: -100px;
      }
    
      60% {
        left: 100%;
      }
    
      to {
        left: 100%;
      }
    }
  
    
  @media screen and (max-width: 1400px) {
  
    
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -35px;
  }
       
  }
  @media screen and (max-width: 1300px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 32%;
      margin-bottom: -55px;
  }
  }
  @media screen and (max-width: 1280px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 34%;
      margin-bottom: -70px;
  }
  }
  @media screen and (max-width: 1260px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -70px;
  }
  }
  
  
  @media screen and (max-width: 1250px) {
  
    
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -30px;
  }
  }
  @media screen and (max-width: 1230px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 31%;
      margin-bottom: -45px;
  }
  }
  @media screen and (max-width: 1225px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -55px;
  }
  }
  @media screen and (max-width: 1200px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -115px;
  }
  .service-main-head{
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 0%;
    margin-top: -40%;
    margin-bottom: 0%;
      .green-text{
        color: #25ce4a;
      }
    }
  }
  @media screen and (max-width: 1170px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 34%;
      margin-bottom: -130px;
  }
  
  }
  @media screen and (max-width: 1150px) {
    
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -145px;
  }
   
  }
  @media screen and (max-width: 1120px) {
  
    .service-main-head{
      font-size: 45px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 10%;
      margin-right: 0%;
      margin-top: -40%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -135px;
  }
  }
  @media screen and (max-width: 1070px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 45%;
      margin-bottom: -150px;
  }
  }
  
  @media screen and (max-width: 1030px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -165px;
  }
  
    
  }
  @media screen and (max-width: 1000px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -185px;
  }
  }
  @media screen and (max-width: 970px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 940px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -150px;
  }
  }
  
  
  @media screen and (max-width: 950px) {
  
    .form-section{
      margin: auto;
      margin-left: 0%;
      margin-right: 0%;
      margin-top: -30%;
  
    }
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 55%;
      margin-bottom: -145px;
  }
     
  }
  @media screen and (max-width: 920px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 58%;
      margin-bottom: -165px;
  }
  }
  @media screen and (max-width: 890px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 860px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -205px;
  }
  }
  @media screen and (max-width: 850px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -160px;
  }
  }
  @media screen and (max-width: 830px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -170px;
  }
  }
  @media screen and (max-width: 800px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -190px;
  }
  }
  @media screen and (max-width: 785px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 70%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 770px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -212px;
  }
  }
  @media screen and (max-width: 760px) {
  
    .service-main-head{
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 15%;
      margin-right: 0%;
      margin-top: 10%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
     
    
      .bg-view {
           width: 100%;
           height: 100%;
           margin-left: 0%;
           margin-right: 0%;
           margin-top: 30%;
           margin-bottom: -100rem;
    }
  
     .full-section{
      width:  100%;
      margin-left: 10%;
    }
    
    
    .button {
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        padding-block: 0.5rem;
        padding-inline: 1.25rem;
        background-color: #25ce4a;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        height: 45px;
        width: 180px;
        justify-content: center;
        color: #ffff;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 15px;
        margin-left: 15%;
        margin-top: 20px;
      }
     
    
  }
  @media screen and (max-width: 670px) {
  
    .bg-view{
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: 23%;
        margin-bottom: -100rem;
    }
  }
  @media screen and (max-width: 640px) {
  
    .bg-view{
        
      margin-top: 17%;
      margin-bottom: -100rem;
    }
  }
  
  @media screen and (max-width: 560px) {
  
    .service-main-head{
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 15%;
      margin-right: 0%;
      margin-top: 10%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
      
    
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: 25%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: 0%;
  
    }
    
    
    .button {
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        padding-block: 0.5rem;
        padding-inline: 1.25rem;
        background-color: #25ce4a;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        height: 45px;
        width: 180px;
        justify-content: center;
        color: #ffff;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 15px;
        margin-left: 15%;
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 450px) {
      .service-main-head{
        font-size: 29px;
        font-family: 'Poppins', sans-serif;
        color: #1c2237;
        font-weight: 600;
        margin-left: 15%;
        margin-right: 0%;
        margin-top: 10%;
        margin-bottom: 0%;
          .green-text{
            color: #25ce4a;
          }
        }
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: -62%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: -15%;
  
    }
      
    }
    @media screen and (max-width: 365px) {
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: -80%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: -20%;
  
    }
      
    }