.form-box {
    max-width: 300px;
    background: #f1f7fe;
    overflow: hidden;
    border-radius: 16px;
    color: #010101;
    width: 290px;
    height: 410px;
    background: #ffff;
    border-radius: 30px;
    box-shadow: 0 0 40px -10px #000;
    margin: calc(12vh - 220px) auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    position: relative;
      margin-bottom: -12.5%;
      margin-top: -35%;
      margin-left: 64%;
    }
    
    .form {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px 24px 24px;
      gap: 16px;
      text-align: center;
    }
    
    /*Form text*/
    .title {
      font-weight: bold;
      font-size: 1.6rem;
      color: #1c2237;
    }
    
    .subtitle {
      font-size: 1rem;
      color: #666;
    }
    
    /*Inputs box*/
    .form-container {
      overflow: hidden;
      border-radius: 8px;
      background-color: #fff;
      margin: 1rem 0 .5rem;
      width: 100%;
    }
    
    .input-field {
      min-height: 45px;
      max-width: 150px;
      padding: 0 1rem;
      color: #fff;
      font-size: 15px;
      border: 2px solid #1c2237;;
      margin-bottom: 5px;
      border-radius: 10px;
      background-color: transparent;
    }
     /*{background-color: #e0ecfb;}
       padding: 16px;
     */
    .form-section {
    
      font-size: .85rem;
      text-align: center;
      box-shadow: rgb(0 0 0 / 8%) 0 -1px;
    }
    
    .form-section a {
      font-weight: bold;
      color: #0066ff;
      transition: color .3s ease;
    }
    
    .form-section a:hover {
      color: #005ce6;
      text-decoration: underline;
    }
    
  /*button-form*/
    .form button {
      background-color: #25ce4a;
      color: #fff;
      border: 0;
      border-radius: 24px;
      padding: 10px 16px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color .3s ease;
    }
    
    .form button:hover {
      background-color: #005ce6;
    }
  
  
  
  
    
  /*Responsive */
  
  @media screen and (max-width: 1400px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -13%;
      margin-left: 64%;
      }
  
       
  }
  
  
  @media screen and (max-width: 1250px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -17.5%;
      margin-left: 64%;
      }
  
  
  }
  
  
  
  @media screen and (max-width: 1150px) {
    
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -39%;
      margin-bottom: -13.5%;
      margin-left: 64%;
      }
  
   
  }
  @media screen and (max-width: 1120px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
  
    
  
  }
  @media screen and (max-width: 1050px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -37.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
    
  
  }
  @media screen and (max-width: 950px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -45.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
      .form-section {
        margin: auto;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
    }
    
  
  }
  @media screen and (max-width: 850px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -55%;
      margin-bottom: -15%;
      margin-left: 58%;
      }
    
  
  }
  
  
  
  @media screen and (max-width: 760px) {
  
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: 5%;
      margin-bottom: 23%;
      margin-left: 15%;
      }
    
     
     
    
  }
  
  @media screen and (max-width: 560px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: 8%;
      margin-bottom: 30%;
      margin-left: 15%;
      }
      
    
  }