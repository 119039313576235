.section {
  position: relative;
  margin-bottom: 155px;
  margin-top: 10px;
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1399px;
  margin-right: auto;
  margin-left: auto;
}

.section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 101.7%;
  height: 126.3%;
  bottom: -50px;
  background-image: url("../../../../Assets/Group\ 1.png");
  background-size: cover;
  z-index: -1;
}

.green-text {
  color: #25ce4a;
  background: linear-gradient(to bottom, #050520, #019966);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-light {
  width: 100%;
  height: 100%;
  margin-left: 0%;
  margin-right: 0%;
  margin-top: -30%;
  margin-bottom: -162px;
}

.image-section {
  width: 36%;
  margin-left: 840px;
  margin-top: -30rem;
  margin-bottom: -16rem;
}

.content-section {
  text-align: left;
  padding-top: 30px;
  font-size: 42px;
  line-height: 1.3;
  font-weight: 600;
  font-family: Lato, sans-serif;
  color: #1c2237;
  width: 44%;
  height: 20%;
  margin-left: 115px;
  margin-top: 130px;
  margin-bottom: 12px;
  margin-right: 25px;
}
.head-sub-section {
  width: 90%;
  font-weight: 400;
  height: 50%;
  font-size: 15px;
  line-height: 2;
  margin-left: -20px;
  margin-top: -210px;
  margin-bottom: 0px;
  margin-right: 25px;
}
.content-sub-section {
  text-align: left;
  padding-top: 30px;
  font-size: 23px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #1c2237;
  width: 44%;
  height: 20%;
  margin-left: 140px;
  margin-top: 18%;
  margin-bottom: -5%;
  margin-right: 25px;
}

.content {
  font-family: Guardian Egyptian Regular, sans-serif;
  font-size: 21px;
}
/*button -test*/

.btn-primary-one {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin-left: 7.5rem;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 600;
  font-size: 12px;
  border-radius: 500px;
  overflow: hidden;
  background: #25ce4a;
  color: white;
}

.btn-primary-one span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.btn-primary-one:hover span {
  color: #1c2237;
}

.btn-primary-one::before,
.btn-primary-one::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.btn-primary-one::before {
  content: "";
  background: #1c2237;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.btn-primary-one:hover::before {
  transform: translate3d(100%, 0, 0);
}
@media screen and (max-width: 1360px) {
  .section {
    position: relative;
    margin-bottom: 155px;
    margin-top: 10px;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }
  .section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 128.5%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }

  .image-section {
    width: 35%;
    height: auto;
    margin-left: 45rem;
    margin-top: -25rem;
    margin-bottom: -10px;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -29%;
    margin-bottom: -162px;
  }
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 5rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }

  .content-section {
    text-align: left;
    padding-top: 30px;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #1c2237;
    width: 50%;
    height: 20%;
    margin-left: 80px;
    margin-top: 114px;
    margin-bottom: -115px;
    margin-right: 25px;
  }

  .head-sub-section {
    width: 85%;
    height: 0%;
    font-size: 16px;
    margin-left: -10px;
    margin-top: -120px;
    margin-bottom: 50px;
  }
  .content-sub-section {
    text-align: left;
    padding-top: 30px;
    font-size: 19px;
    line-height: 1.2;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1c2237;
    width: 45%;
    height: 20%;
    margin-left: 88px;
    margin-top: 286px;
    margin-bottom: -50px;
    margin-right: 25px;
  }

  .content {
    font-family: Guardian Egyptian Regular, sans-serif;
    font-size: 21px;
  }
  .cta {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    margin-left: 70px;
    margin-top: 15px;
  }
  .services-sub-section {
    margin-left: auto;
    margin-top: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1358px) {
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 5rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }
}

@media screen and (max-width: 1200px) {
  .image-section {
    width: 40%;
    height: auto;
    margin-left: 40rem;
    margin-top: -30rem;
    margin-bottom: -30px;
  }
  .btn-primary-one {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .image-section {
    width: 40%;
    height: auto;
    margin-left: 40rem;
    margin-top: -30rem;
    margin-bottom: -30px;
  }
}
@media screen and (max-width: 1050px) {
  .image-section {
    width: 45%;
    height: auto;
    margin-left: 33rem;
    margin-top: -30rem;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 960px) {
  .section {
    margin-bottom: 0px;
    margin-top: 80px;
    z-index: 1;
    width: 95%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }

  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -33.5rem;
    width: 171%;
    height: 130%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }

  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -53%;
    margin-bottom: -162px;
  }
  .image-section {
    width: 55%;
    height: auto;
    margin-left: 20%;
    margin-top: -2%;
    margin-bottom: 0px;
  }
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 2rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }

  .content-section {
    text-align: left;
    padding-top: 30px;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #1c2237;
    width: 98%;
    height: 20%;
    margin-left: 35px;
    margin-top: -35px;
    margin-bottom: 12px;
    margin-right: 25px;
  }
  .head-sub-section {
    width: 200%;
    height: 20%;
    font-size: 14px;
    margin-left: -105px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
  .content-sub-section {
    text-align: left;
    padding-top: 30px;
    font-size: 21px;
    line-height: 1.2;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1c2237;
    width: 44%;
    height: 20%;
    margin-left: 140px;
    margin-top: 156px;
    margin-bottom: 50px;
    margin-right: 25px;
  }

  .content {
    font-family: Guardian Egyptian Regular, sans-serif;
    font-size: 21px;
  }
  .cta {
    position: relative;
    margin: auto;
    font-size: 20px;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    margin-left: 15%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .services-sub-section {
    margin-left: -75px;
    margin-top: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    width: 190%;
  }
}
@media screen and (max-width: 850px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -3rem;
    width: 115%;
    height: 133%;
    bottom: -50px;
    background-image: url("../../../../Assets/Group\ 1.png");
    background-size: cover;
    z-index: -1;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -40%;
    margin-bottom: -54px;
  }
  .image-section {
    width: 55%;
    height: auto;
    margin-left: 21%;
    margin-top: -2%;
    margin-bottom: 30px;
  }
  .btn-primary-one {
    margin-top: -34px;
    margin-left: 38px;
  }
}
@media screen and (max-width: 750px) {
  .image-section {
    width: 60%;
    height: auto;
    margin-left: 20%;
    margin-top: 0%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 138%;
    height: 133%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}

@media screen and (max-width: 500px) {
  .image-section {
    width: 100%;
    height: auto;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 60px;
  }
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 155%;
    height: 128%;
    bottom: -50px;

    background-size: cover;
    z-index: -1;
  }
  .head-sub-section {
    width: 135%;
    height: 20%;
    font-size: 14px;
    margin-left: -115px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -75%;
    margin-bottom: -162px;
  }
  .content-section {
    margin-top: -40px;
    font-size: 185%;
    margin-left: 20px;
  }
  .services-sub-section {
    font-size: 11px;
    margin-left: -85px;
  }
}

@media screen and (max-width: 400px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -12rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
  .head-sub-section {
    width: 200%;
    height: 20%;
    font-size: 14px;
    margin-left: -115px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
}
@media screen and (max-width: 365px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -11.2rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}

@media screen and (max-width: 325px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}
