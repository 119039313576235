.about-page{
    margin-top: 0%;
    margin-bottom: 230px;
    justify-items: center;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
}
.about-us{

    margin-top: 1%;
    margin-bottom: -5%;
   

}
.head-about-page{
   
    font-weight: 600;
    padding-top: 100px;
    margin-bottom: 5%;
    margin-left: 10%;
    
}

.head-text-second{
    
    padding-top: 20px;
    color:#1c2237;
    font-size:  50px;
    
}
.sub-about-page{
    margin-top: 0%;

}
.content-about-page{
    margin-left: 10%;
    margin-right: 54%;
    margin-top: 40px;
    font-size: 25px;
    font-weight: 100;
}
.about-img{
    width: 45%;
    height: 10%;
    margin-left: 52%;
    position: relative;
    margin-top: -32%;
    margin-bottom: -3%;

}
.bg-view-about{

    margin-bottom: -70.5%;
}
.head-text{
   
    color: #f00946;
    margin-bottom: 20px;
}
.content-text{
    color: #1c2237;
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
}

/* Learn-more button */


.about-button {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-top: -80px;
    margin-bottom: 90px;
    align-items: center;
    border-radius: 0px;
    background: #183153;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
   }
   
   .about-button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #25ce4a;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
   }
   
   .about-button:hover::after {
    right: auto;
    left: 0;
    width: 100%;
   }
   
   .about-button span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 15px 30px;
    color: #fff;
    font-size: 100%;
    font-weight: 600;
    letter-spacing: 0.1rem;
    z-index: 20;
    transition: all 0.3s ease-in-out;
   }
   
   .about-button:hover span {
    color: #1c2237;
    animation: scaleUp 0.3s ease-in-out;
   }
   
   @keyframes scaleUp {
    0% {
     transform: scale(1);
    }
   
    50% {
     transform: scale(0.95);
    }
   
    100% {
     transform: scale(1);
    }
   }


/*Responsive*/


/* Rect-light adjustment  ( width > 950px ) */

/* Start */

@media screen and (max-width: 1420px) {
    .bg-view-about{
    
        margin-bottom: -70.5%;
    }
}

@media screen and (max-width: 1390px) {
    .bg-view-about{
    
        margin-bottom: -72.5%;
    }
}

@media screen and (max-width: 1350px) {
    .bg-view-about{
    
        margin-bottom:-74.5%;
    }
}
@media screen and (max-width: 1310px) {
    .bg-view-about{
    
        margin-bottom: -78%;
    }
}
@media screen and (max-width: 1280px) {
    .bg-view-about{
    
        margin-bottom: -79.5%;
    }
}
@media screen and (max-width: 1240px) {
    .bg-view-about{
    
        margin-bottom: -83.5%;
    }
}
@media screen and (max-width: 1225px) {
    .bg-view-about{
    
        margin-bottom: -85%;
    }
}
@media screen and (max-width: 1190px) {
    .bg-view-about{
    
        margin-bottom:-88%;
    }
}
@media screen and (max-width: 1160px) {
    .bg-view-about{
    
        margin-bottom:-90.5%;
    }
}
@media screen and (max-width: 1110px) {
    .bg-view-about{
    
        margin-bottom: -94.5%;
    }
}
@media screen and (max-width: 1098px) {
    .bg-view-about{
    
        margin-bottom:-95.5%;
    }
}

@media screen and (max-width: 1060px) {
    .bg-view-about{
    
        margin-bottom:-99.5%;
    }
}
@media screen and (max-width: 1040px) {
    .bg-view-about{
    
        margin-bottom:-107%;
    }
}
@media screen and (max-width: 1020px) {
    .bg-view-about{
    
        margin-bottom:-109%;
    }
}
@media screen and (max-width: 1000px) {
    .bg-view-about{
    
        margin-bottom:-112%;
    }
}
@media screen and (max-width: 970px) {
    .bg-view-about{
    
        margin-bottom: -115.5%;
    }
}


/* Stop*/




@media screen and (max-width: 950px) {

.about-page{
    margin-top: 0%;
    margin-bottom: 138px;
    justify-items: center;

    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
}
.about-us{

    margin-top: 7.5%;
    margin-bottom: 14%;
   

}
.head-about-page{
   
    font-weight: 600;
    padding-top: 100px;
    margin-bottom: 5%;
    margin-left: 10%;
    
}

.head-text-second{
    
    padding-top: 20px;
    color:#1c2237;
    font-size:  40px;
    
}
.sub-about-page{
    margin-top: 0%;

}
.content-about-page{
    margin-left: 10%;
    margin-right: 54%;
    margin-top: 40px;
    font-size: 21px;
    font-weight: 100;

}
.about-img{
    width: 45%;
    height: 10%;
    margin-left: 57%;
    position: relative;
    margin-top: -38%;
    margin-bottom: 5%;

}
.bg-view-about{

    margin-bottom: -110%;
}


}

/* Rect-light adjustment  ( width < 930px ) */

/* Start */

@media screen and (max-width: 930px) {

    .bg-view-about{

    margin-bottom: -112%;
}

}

@media screen and (max-width: 923px)
{
.bg-view-about {
    margin-bottom:-113.5%;
}
}
@media screen and (max-width: 910px)
{
.bg-view-about {
    margin-bottom: -114%;
}
}

@media screen and (max-width: 900px)
{
.bg-view-about {
    margin-bottom: -116%;
}
}
@media screen and (max-width: 888px)
{
.bg-view-about {
    margin-bottom:-123%;
}
}
@media screen and (max-width: 880px)
{
.bg-view-about {
    margin-bottom: -122%;
}
}
@media screen and (max-width: 870px)
{
.bg-view-about {
    margin-bottom:-126%;
}
}

@media screen and (max-width: 850px)
{
.bg-view-about {
    margin-bottom: -129%;
}
}

@media screen and (max-width: 830px)
{
.bg-view-about {
    margin-bottom: -130%;
}

}

@media screen and (max-width: 820px)
{
.bg-view-about {
    margin-bottom:-134%;
}

}

@media screen and (max-width: 810px)
{
.bg-view-about {
    margin-bottom: -136%;
}

}


@media screen and (max-width: 802px)
{
.bg-view-about {
    margin-bottom:-136%;
}

}

/* End */



@media screen and (max-width: 800px) {



    .about-page{
        margin-top: 0%;
        margin-bottom: -80px;
        justify-items: center;
    
        z-index: 1;
        width: 100%;
        max-width: 1399px;
        margin-right: auto;
        margin-left: auto;
    }
    .about-us{
    
        margin-top: -3.5%;
        margin-bottom: 17%;
       
    
    }
    .head-about-page{
       
        font-weight: 600;
        padding-top: 100px;
        margin-bottom: 1%;
        margin-left: 10%;
        
    }
    
    .head-text-second{
        
        padding-top: 20px;
        color:#1c2237;
        font-size:  40px;
        
    }
    .sub-about-page{
        margin-top: 0%;
    
    }
    .content-about-page{
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 361px;
        font-size: 20px;
        font-weight: 100;
    
    }
    .about-img{
        width: 60%;
        height: 10%;
        margin-left: 5%;
        position: relative;
        margin-top: -90%;
        margin-bottom: 5%;
    
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 25px;
    }
    .bg-view-about{
    
        margin-bottom: -190%;
    }
    .about-button {
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: 10%;
        margin-top: 23px;
        margin-bottom: 90px;
        align-items: center;
        border-radius: 0px;
        background: #183153;
        font-family: "Montserrat", sans-serif;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: none;
       }

}


/* Rect-light adjustment  ( width < 800px ) */

/* Start */


@media screen and (max-width: 780px)
{
.bg-view-about {
    margin-bottom: -197%;
}

}
@media screen and (max-width: 769px)
{
.bg-view-about {
    margin-bottom: -192.5%;
}

}


@media screen and (max-width: 750px)
{
.bg-view-about {
    margin-bottom:-204.5%;
}

}

@media screen and (max-width: 730px)
{
.bg-view-about {
    margin-bottom:-208%;
}

}
@media screen and (max-width: 715px)
{
.bg-view-about {
    margin-bottom:-211%;
}

}

@media screen and (max-width: 700px)
{
.bg-view-about {
    margin-bottom: -217%;
}

}
@media screen and (max-width: 690px)
{
.bg-view-about {
    margin-bottom: -221%;
}

}





@media screen and (max-width: 680px) {

    .about-us{
    
        margin-top: -4.5%;
        margin-bottom: 17%;
    
    }
  
    .content-about-page{
        margin-left: 10%;
        margin-right: 16%;
        margin-top: 361px;
        font-size: 20px;
        font-weight: 100;
    
    }
    .about-img{
        width: 70%;
        height: 10%;
        margin-left: 5%;
        position: relative;
        margin-top: -110%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -224.5%;
    }



}


@media screen and (max-width: 670px)
{
.bg-view-about {
    margin-bottom: -229%;
}

}
@media screen and (max-width: 650px)
{
.bg-view-about {
    margin-bottom:-241%;
}
.about-us{
    
    margin-top: -4.5%;
    margin-bottom: 20%;
  
  }

}
@media screen and (max-width: 630px)
{
.bg-view-about {
    margin-bottom:-251%;
}

}
@media screen and (max-width: 610px)
{
.bg-view-about {
    margin-bottom: -257%;
}
.about-img{
    width: 70%;
    height: 10%;
    margin-left: 5%;
    position: relative;
    margin-top: -125%;
    margin-bottom: 5%;

}

}

@media screen and (max-width: 590px)
{
.bg-view-about {
    margin-bottom:-266%;
}

}
@media screen and (max-width: 580px)
{
.bg-view-about {
    margin-bottom: -269%;
}

}
@media screen and (max-width: 570px)
{
.bg-view-about {
    margin-bottom: -275%;
}

}
@media screen and (max-width: 560px)
{
.bg-view-about {
    margin-bottom:-280%;
}

}
@media screen and (max-width: 550px)
{
.bg-view-about {
    margin-bottom:-279%;
}

}
@media screen and (max-width: 540px)
{
.bg-view-about {
    margin-bottom:-290%;
}

}
@media screen and (max-width: 530px)
{
.bg-view-about {
    margin-bottom:-315%;
}

}
@media screen and (max-width: 525px)
{
.bg-view-about {
    margin-bottom:-297%;
}
}
@media screen and (max-width: 520px)
{
.bg-view-about {
    margin-bottom:-330%;
}

}
@media screen and (max-width: 510px)
{
.bg-view-about {
    margin-bottom:-335%;
}
.about-img {
    width: 85%;
    height: 10%;
    margin-left: 0%;
    position: relative;
    margin-top: -159%;
    margin-bottom: 5%;
}

}
@media screen and (max-width: 503px)
{
.bg-view-about {
    margin-bottom:-340%;
}

}







/* Stop */




/* Mobile Responsive */

@media screen and (max-width: 435px) {


    .about-us{
    
        margin-top: -8.5%;
        margin-bottom: 30%;
    
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 20px;
    }
  
    .content-about-page{
        margin-left: 10%;
        margin-right: 16%;
        margin-top: 400px;
        font-size: 16px;
        font-weight: 100;
    
    }
    .head-text-second {
        padding-top: 20px;
        color: #1c2237;
        font-size: 26px;
    }
    .about-img{
        width: 100%;
        height: 10%;
        margin-left: -5%;
        position: relative;
        margin-top: -185%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -387%;
    }
}
@media screen and (max-width: 420px) {
    .bg-view-about {
        margin-bottom: -413%;
    }
    .about-img{
        width: 100%;
        height: 10%;
        margin-left: -5%;
        position: relative;
        margin-top: -210%;
        margin-bottom: 5%;
    
    }
}

@media screen and (max-width: 400px) {


    .about-us{
    
        margin-top: -5.5%;
        margin-bottom: -20%;
    
    }

  
    .content-about-page{
        margin-left: 10%;
        margin-right: 13%;
        margin-top: 310px;
        font-size: 16px;
        font-weight: 100;
    
    }
 
    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -190%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -378%;
    }


}
@media screen and (max-width: 380px) {
    .bg-view-about {
        margin-bottom:-395%;
    }
    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -200%;
        margin-bottom: 5%;
    
    }  
}
@media screen and (max-width: 360px) {


    .content-about-page{
        margin-left: 10%;
        margin-right: 13%;
        margin-top: 310px;
        font-size: 16px;
        font-weight: 100;
    
    }
 
    .about-us{
    
        margin-top: -10.5%;
        margin-bottom: 30%;
    
    }

    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -220%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
           margin-bottom: -433.5%;
    }


}
@media screen and (max-width: 320px) {


    .content-about-page{
        margin-left: 10%;
        margin-right: 5%;
        margin-top: 282px;
        font-size: 13px;
        font-weight: 100;
    
    }
 
    .about-us{
    
        margin-top: -30.5%;
        margin-bottom: 30%;
    
    }

    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -242%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -437%;
    }
    .head-text-second {
        padding-top: 20px;
        color: #1c2237;
        font-size: 23px;
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .about-button {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-top: -19px;
    margin-bottom: 90px;
    align-items: center;
    border-radius: 0px;
    background: #183153;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
}
 


}





