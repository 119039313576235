.navbar {
    background: #1c2237;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    position: -webkit-sticky;
    position:sticky;
    top: 0;
    z-index: 999;
    transition: top 0.5s;
      
    
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    
   
  }
  
  .navbar-logo {
    color: #25ce4a;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-left: -50px;
  }
  
  .navbar-icon {
    margin-right: 3px;
    margin-left: -7px;
    margin-bottom: 8px;
    height: 27px;
    width: 146px;

  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #800080;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 13px;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
    margin-right: -15px;
    
  }
  .btn--mobile{
    text-align: center;
    border-radius: 10px;

    
    text-decoration: none;
    font-size: 1rem;
    background-color: transparent;
    color: #fff;
    padding: 8px 18px;
    font-size: 14px;
    margin-left: 19px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}
.company-name{
  size: 10px;
  width: 170px;
  height: 20px;
  margin-left: -55px;
}

  
  @media screen and (max-width: 850px) {
    .container{
      margin-top: 21px;

      
    }
    .menu-icon {
      display: none;
      margin-right: -4px;
      margin-top: -5px;
      
    }
    .navbar {
      background: #1c2237;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 999;
      
      
    }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 95%;
      height: 40rem;
      padding-bottom: 20%;
      margin-left: 10px;
      border-radius: 20px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      font-size: 16px;
    }
  
    .nav-links:hover {
      color: #00FF00;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      font-size: 10px;
      margin-left: -90px;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 120px;
    }
    .btn--mobile{
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    font-size: 15px;
    background-color: transparent;
    color: #fff;
    padding: 12px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
 }
   
}