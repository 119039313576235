.form-1 {
    width: 290px;
    height: 410px;
    background: #ffff;
    border-radius: 30px;
    box-shadow: 0 0 40px -10px #000;
    margin: calc(12vh - 220px) auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    position: relative;
    margin-bottom: -12.5%;
    margin-top: -35%;
    margin-left: 64%;
    }
    .input-box{
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background: none;
      outline: none;
      resize: none;
      border: 0;
      font-family: "Montserrat", sans-serif;
      transition: all 0.3s;
      border-bottom: 2px solid #bebed2;
      
    }
    .input-box:focus {
      border-bottom: 2px solid #78788c;
    }
    .div-form{
      content: "Hi";
      position: absolute;
      bottom: -15px;
      right: -20px;
      background: #25ce4a;
      color: black;
      width: 224px;
      padding: 19px 4px 10px 0;
      border-radius: 6px;
      padding-top: 10px;
      font-size: 15px;
      box-shadow: 10px 10px 40px -14px #000;
    }
    .span-form{
      margin: 0 5px 0 15px;
    }
   .button-form{
    float: right;
    padding: 8px 12px;
    margin: 8px 0 0;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #bebed2;
    background: 0;
    color: #1c2237;
    cursor: pointer;
    transition: all 0.3s;
    
   }
   .button-form:hover {
    background: #25ce4a;
    color: #1c2237;
  }
  .p-form:before {
    content: attr(type);
    display: block;
    margin: 28px 0 0;
    font-size: 14px;
    color: #5a5a5a;
  }
  .head-2{
    
      margin: 10px 0;
      padding-bottom: 10px;
      width: 180px;
      color: #1c2237;
      border-bottom: 3px solid #78788c;
   
  }
  
  
  /*Responsive */
  
  @media screen and (max-width: 1400px) {
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -13%;
      margin-left: 64%;
    }
  
       
  }
  
  
  @media screen and (max-width: 1250px) {
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -17.5%;
      margin-left: 64%;
    }
  
  
  }
  
  
  
  @media screen and (max-width: 1150px) {
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -39%;
      margin-bottom: -13.5%;
      margin-left: 64%;
    }
  
  
   
  }
  @media screen and (max-width: 1120px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 1050px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -37.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 950px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -45.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 850px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -55%;
      margin-bottom: -15%;
      margin-left: 58%;
    }
  
  }
  
  
  
  @media screen and (max-width: 760px) {
  
  
      .form-1 {
     
        width: 290px;
        height: 410px;
        background: #ffff;
        border-radius: 30px;
        box-shadow: 0 0 40px -10px #000;
        margin: calc(12vh - 220px) auto;
        padding: 20px 30px;
        max-width: calc(100vw - 40px);
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        position: relative;
        margin-top: 5%;
        margin-bottom: 23%;
        margin-left: 15%;
      }
    
     
     
    
  }
  
  @media screen and (max-width: 560px) {
  
   
      .form-1 {
     
        width: 290px;
        height: 410px;
        background: #ffff;
        border-radius: 30px;
        box-shadow: 0 0 40px -10px #000;
        margin: calc(12vh - 220px) auto;
        padding: 20px 30px;
        max-width: calc(100vw - 40px);
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        position: relative;
        margin-top: 8%;
        margin-bottom: 30%;
        margin-left: 15%;
      }
    
  }