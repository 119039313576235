* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 9px 18px;
  font-size: 14px;
  margin-bottom: 2px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #00FF00;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

.green {
  background: #25ce4a;
  color: #fff;
  border: none;
}

.green:hover {
  background-color: #242424;
}


.navbar {
    background: #1c2237;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    position: -webkit-sticky;
    position:sticky;
    top: 0;
    z-index: 999;
    transition: top 0.5s;
      
    
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    
   
  }
  
  .navbar-logo {
    color: #25ce4a;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-left: -50px;
  }
  
  .navbar-icon {
    margin-right: 3px;
    margin-left: -7px;
    margin-bottom: 8px;
    height: 27px;
    width: 146px;

  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #800080;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 13px;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
    margin-right: -15px;
    
  }
  .btn--mobile{
    text-align: center;
    border-radius: 10px;

    
    text-decoration: none;
    font-size: 1rem;
    background-color: transparent;
    color: #fff;
    padding: 8px 18px;
    font-size: 14px;
    margin-left: 19px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}
.company-name{
  size: 10px;
  width: 170px;
  height: 20px;
  margin-left: -55px;
}

  
  @media screen and (max-width: 850px) {
    .container{
      margin-top: 21px;

      
    }
    .menu-icon {
      display: none;
      margin-right: -4px;
      margin-top: -5px;
      
    }
    .navbar {
      background: #1c2237;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 999;
      
      
    }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 95%;
      height: 40rem;
      padding-bottom: 20%;
      margin-left: 10px;
      border-radius: 20px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      font-size: 16px;
    }
  
    .nav-links:hover {
      color: #00FF00;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
      font-size: 10px;
      margin-left: -90px;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 120px;
    }
    .btn--mobile{
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    font-size: 15px;
    background-color: transparent;
    color: #fff;
    padding: 12px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
 }
   
}
.footer-container {
  background-color: #1c2237;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
  size: 50%;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 24px;
  border-radius: 5px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #1564ed;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
  margin-top: -20px;
  background-color: #1c2237;
  
  margin-left: -45px ;

  

}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  margin-left: 66px;
  margin-right: -51px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  
}

.social-logo {
  color: #0757a8;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  margin-top: 20px;
}
.input-group {
  display: flex;
  align-items: center;
}

.input {
  min-height: 50px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 1px solid #1564ed;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
}

.button--submit {
  min-height: 50px;
  padding: .5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #1564ed;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
  font-family: 'Poppins', sans-serif;
}

.button--submit:hover {
  background-color: #f00946;
}

.input:focus, .input:focus-visible {
  border-color: #3898EC;
  outline: none;
}


@media screen and (max-width: 820px) {
  

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap{
    margin-left: 52px;
  }
 .company-mail{
    font-size: 13px;
 }
}



.chat-button{
    position: fixed;
    bottom: 15px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #00bfa5;
    color: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
}

@media screen and (max-width: 420px) {

    .chat-button{
        position: fixed;
        bottom: 1%;
        right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #00bfa5;
        color: #fff;
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 9999;
    }
    
}
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
    
  .loading-spinner {
    border: 7px solid #1c2237;
    border-top: 7px solid #25ce4a;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
  }
  .loading-icon{
    color: #25ce4a;
    justify-self: start;
    margin-left: -90px;
    cursor: pointer;
    text-decoration: none;
    padding-left: 10px;
    font-size: 4rem;
    display: flex;
    align-items: center;
  }
    
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
    
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.content-page{
   
    background-color: white;
    height: 350px;
    align-items: center;
    text-align: center;
}
.content-heading{
    text-align: center;
    font-size: 48px;
    line-height: 56px;
    margin-top: 34PX;
    margin-bottom: -15px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 21px;
   
}
.content-para{
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    margin-top: 38PX;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 200px;
    margin-right: 206px;

}
.content-btn{

    margin-top: 45px;
    color: white;
    background-color: #276afb;;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    

   
  
    &:hover{
        background-color: #f00946;
        border: 2px solid black;
        color: white;
    }
}
@media screen and (max-width: 950px ) {
    .content-page{
   
        background-color: white;
        height: 300px;
        align-items: center;
    }

    .content-heading{
        text-align: center;
        font-size: 33px;
        line-height: 56px;
        margin-top: 20PX;
        margin-bottom: 16px;
        color: black;
        font-family: 'Poppins', sans-serif;
       
    }
    .content-para{
        text-align: center;
        font-size: 17px;
        line-height: 22px;
        margin-top: 21px;
        color: black;
        font-family: 'Poppins', sans-serif;
        margin-left: 15px;
        margin-right: 15px;
       
    
    }
    .content-btn{
        
        margin-top: 44PX;
        color: white;
        background-color: #276afb;
        border: 1px solid #F5F5F5;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        
      
        &:hover{
            background-color: #f00946;
            border: 2px solid black;
            color: white;
        }


    }
}
.section {
  position: relative;
  margin-bottom: 155px;
  margin-top: 10px;
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1399px;
  margin-right: auto;
  margin-left: auto;
}

.section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 101.7%;
  height: 126.3%;
  bottom: -50px;
  background-image: url("/static/media/Group 1.14d4e7c4.png");
  background-size: cover;
  z-index: -1;
}

.green-text {
  color: #25ce4a;
  background: linear-gradient(to bottom, #050520, #019966);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-light {
  width: 100%;
  height: 100%;
  margin-left: 0%;
  margin-right: 0%;
  margin-top: -30%;
  margin-bottom: -162px;
}

.image-section {
  width: 36%;
  margin-left: 840px;
  margin-top: -30rem;
  margin-bottom: -16rem;
}

.content-section {
  text-align: left;
  padding-top: 30px;
  font-size: 42px;
  line-height: 1.3;
  font-weight: 600;
  font-family: Lato, sans-serif;
  color: #1c2237;
  width: 44%;
  height: 20%;
  margin-left: 115px;
  margin-top: 130px;
  margin-bottom: 12px;
  margin-right: 25px;
}
.head-sub-section {
  width: 90%;
  font-weight: 400;
  height: 50%;
  font-size: 15px;
  line-height: 2;
  margin-left: -20px;
  margin-top: -210px;
  margin-bottom: 0px;
  margin-right: 25px;
}
.content-sub-section {
  text-align: left;
  padding-top: 30px;
  font-size: 23px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #1c2237;
  width: 44%;
  height: 20%;
  margin-left: 140px;
  margin-top: 18%;
  margin-bottom: -5%;
  margin-right: 25px;
}

.content {
  font-family: Guardian Egyptian Regular, sans-serif;
  font-size: 21px;
}
/*button -test*/

.btn-primary-one {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin-left: 7.5rem;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 600;
  font-size: 12px;
  border-radius: 500px;
  overflow: hidden;
  background: #25ce4a;
  color: white;
}

.btn-primary-one span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.btn-primary-one:hover span {
  color: #1c2237;
}

.btn-primary-one::before,
.btn-primary-one::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.btn-primary-one::before {
  content: "";
  background: #1c2237;
  width: 120%;
  left: -10%;
  -webkit-transform: skew(30deg);
          transform: skew(30deg);
  transition: -webkit-transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1), -webkit-transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.btn-primary-one:hover::before {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
@media screen and (max-width: 1360px) {
  .section {
    position: relative;
    margin-bottom: 155px;
    margin-top: 10px;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }
  .section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 128.5%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }

  .image-section {
    width: 35%;
    height: auto;
    margin-left: 45rem;
    margin-top: -25rem;
    margin-bottom: -10px;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -29%;
    margin-bottom: -162px;
  }
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 5rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }

  .content-section {
    text-align: left;
    padding-top: 30px;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #1c2237;
    width: 50%;
    height: 20%;
    margin-left: 80px;
    margin-top: 114px;
    margin-bottom: -115px;
    margin-right: 25px;
  }

  .head-sub-section {
    width: 85%;
    height: 0%;
    font-size: 16px;
    margin-left: -10px;
    margin-top: -120px;
    margin-bottom: 50px;
  }
  .content-sub-section {
    text-align: left;
    padding-top: 30px;
    font-size: 19px;
    line-height: 1.2;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1c2237;
    width: 45%;
    height: 20%;
    margin-left: 88px;
    margin-top: 286px;
    margin-bottom: -50px;
    margin-right: 25px;
  }

  .content {
    font-family: Guardian Egyptian Regular, sans-serif;
    font-size: 21px;
  }
  .cta {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    margin-left: 70px;
    margin-top: 15px;
  }
  .services-sub-section {
    margin-left: auto;
    margin-top: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1358px) {
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 5rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }
}

@media screen and (max-width: 1200px) {
  .image-section {
    width: 40%;
    height: auto;
    margin-left: 40rem;
    margin-top: -30rem;
    margin-bottom: -30px;
  }
  .btn-primary-one {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .image-section {
    width: 40%;
    height: auto;
    margin-left: 40rem;
    margin-top: -30rem;
    margin-bottom: -30px;
  }
}
@media screen and (max-width: 1050px) {
  .image-section {
    width: 45%;
    height: auto;
    margin-left: 33rem;
    margin-top: -30rem;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 960px) {
  .section {
    margin-bottom: 0px;
    margin-top: 80px;
    z-index: 1;
    width: 95%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }

  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -33.5rem;
    width: 171%;
    height: 130%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }

  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -53%;
    margin-bottom: -162px;
  }
  .image-section {
    width: 55%;
    height: auto;
    margin-left: 20%;
    margin-top: -2%;
    margin-bottom: 0px;
  }
  .btn-primary-one {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.9rem 2rem;
    margin-left: 2rem;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 600;
    font-size: 12px;
    border-radius: 500px;
    overflow: hidden;
    background: #25ce4a;
    color: white;
  }

  .content-section {
    text-align: left;
    padding-top: 30px;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #1c2237;
    width: 98%;
    height: 20%;
    margin-left: 35px;
    margin-top: -35px;
    margin-bottom: 12px;
    margin-right: 25px;
  }
  .head-sub-section {
    width: 200%;
    height: 20%;
    font-size: 14px;
    margin-left: -105px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
  .content-sub-section {
    text-align: left;
    padding-top: 30px;
    font-size: 21px;
    line-height: 1.2;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1c2237;
    width: 44%;
    height: 20%;
    margin-left: 140px;
    margin-top: 156px;
    margin-bottom: 50px;
    margin-right: 25px;
  }

  .content {
    font-family: Guardian Egyptian Regular, sans-serif;
    font-size: 21px;
  }
  .cta {
    position: relative;
    margin: auto;
    font-size: 20px;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    margin-left: 15%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .services-sub-section {
    margin-left: -75px;
    margin-top: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    width: 190%;
  }
}
@media screen and (max-width: 850px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -3rem;
    width: 115%;
    height: 133%;
    bottom: -50px;
    background-image: url("/static/media/Group 1.14d4e7c4.png");
    background-size: cover;
    z-index: -1;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -40%;
    margin-bottom: -54px;
  }
  .image-section {
    width: 55%;
    height: auto;
    margin-left: 21%;
    margin-top: -2%;
    margin-bottom: 30px;
  }
  .btn-primary-one {
    margin-top: -34px;
    margin-left: 38px;
  }
}
@media screen and (max-width: 750px) {
  .image-section {
    width: 60%;
    height: auto;
    margin-left: 20%;
    margin-top: 0%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 138%;
    height: 133%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}

@media screen and (max-width: 500px) {
  .image-section {
    width: 100%;
    height: auto;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 60px;
  }
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 155%;
    height: 128%;
    bottom: -50px;

    background-size: cover;
    z-index: -1;
  }
  .head-sub-section {
    width: 135%;
    height: 20%;
    font-size: 14px;
    margin-left: -115px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
  .bg-light {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: -75%;
    margin-bottom: -162px;
  }
  .content-section {
    margin-top: -40px;
    font-size: 185%;
    margin-left: 20px;
  }
  .services-sub-section {
    font-size: 11px;
    margin-left: -85px;
  }
}

@media screen and (max-width: 400px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -12rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
  .head-sub-section {
    width: 200%;
    height: 20%;
    font-size: 14px;
    margin-left: -115px;
    margin-top: -129px;
    margin-bottom: 50px;
    margin-right: 25px;
  }
}
@media screen and (max-width: 365px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -11.2rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}

@media screen and (max-width: 325px) {
  .section::before {
    content: "";
    position: absolute;
    top: -16rem;
    left: -10rem;
    width: 155%;
    height: 128%;
    bottom: -50px;
    background-size: cover;
    z-index: -1;
  }
}

.cards-2{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section-2{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;   
  }
  .full-items-2{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 75px;

  }
  .head-card-module-2{
      
   text-align: center;
   font-size: 29px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 10%;
   margin-right: 15%;
  }   
  .card1-2{
   width: 190px;
   height: 254px;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2-2{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3-2{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4-2{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }

     
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}

@media screen and (max-width: 1150px) {

   
  .cards-2{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 0px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section-2{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;
        
        
  }
  .full-items-2{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module-2{
      
   text-align: center;
   font-size: 25px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 20%;
   margin-right: 20%;
  }   

  .card1-2{
   width: 200px;
   height: 235px;
   
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2-2{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3-2{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4-2{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 39%;
    margin-top: -2%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

@media screen and (max-width: 800px) {

      
  .cards-2{
   z-index: 1;
   width: 100%;
   height: 120rem;
   margin-top: 0px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section-2{
   z-index: 1;
   display: grid;
   width: 100%;
   height: 70rem;
   margin-left: 0%;
   margin-top: 30px;
   
        
        
  }
  .full-items-2{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module-2{
      
   text-align: center;
   font-size: 23px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: -5%;
   margin-right: 5%;
  }   

  .card1-2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2-2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3-2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4-2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 145px;
     }
     .card-img{
        width: 90%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 32%;
    margin-top: 80%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

}
@media screen and (max-width: 650px) {

   .cards-section-2{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px;           
     }
     .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 30%;
       margin-top: 90%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }
     .cards-2{
      height: 115rem;
     }
     .card1-2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2-2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3-2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4-2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
}

@media screen and (max-width: 450px) {

   .cards-2{
      z-index: 1;
      width: 100%;
      height: 90rem;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      align-items: center;
      justify-content: center;
      background-color: #1c2237;
   
     }
     .cards-section-2{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px; 
     }
     .full-items-2{
      z-index: 1;
      width: 100%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 50px;
      padding-left: 50px;
   
     }
     .head-card-module-2{
         
      text-align: center;
    font-size: 18px;
    padding-top: 15%;
    color: #fff;
    font-weight: 600;
    margin-left: -15%;
    margin-right: -15%;
     }   
   
     .card1-2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 18%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2-2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3-2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4-2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
        .card-name{
           font-size: 21px;
           font-weight: 600;
           text-align: center;
           font-family: 'Poppins', sans-serif;
           color: black;
           margin-top: 115px;
        }
        .card-img{
           width: 90%;
           margin-top: 15%;
           margin-bottom: -40%;
        }
   .card{    
      width: 20%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
   }
   .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 20%;
       margin-top: 40%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }

}

  .second-button:after {
   content: " ";
   width: 0%;
   height: 100%;
   background: #25ce4a;
   position: absolute;
   transition: all 0.4s ease-in-out;
   right: 0;
  }
  
  .second-button:hover::after {
   right: auto;
   left: 0;
   width: 100%;
  }
  
  .second-button span {
   text-align: center;
   text-decoration: none;
   width: 100%;
   padding: 15px 30px;
   color: #fff;
   font-size: 100%;
   font-weight: 600;
   letter-spacing: 0.1rem;
   z-index: 20;
   transition: all 0.3s ease-in-out;
  }
  
  .second-button:hover span {
   color: #1c2237;
   -webkit-animation: scaleUp 0.3s ease-in-out;
           animation: scaleUp 0.3s ease-in-out;
  }
  
  @-webkit-keyframes scaleUp {
   0% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  
   50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
   }
  
   100% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  }
  
  @keyframes scaleUp {
   0% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  
   50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
   }
  
   100% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  }
.pricing__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
}

.pricing__container-card {
  background: linear-gradient(45deg, #0660e9 0%, #276afb 100%);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 500px;
  text-decoration: none;
  border-radius: 4px;
}

.pricing__container-card:nth-child(2) {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background: #242424;
}

.pricing__container-card:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #1c2237;
}

.pricing__container-card:nth-child(2) {
  margin: 0 24px;
}

.pricing__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
}

.pricing__heading {
  color: #1c2237;
  margin-bottom: 24px;
}

.pricing__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
}

.pricing__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
}

.pricing__container-cardInfo h4 {
  font-size: 40px;
}

.pricing__container-cardInfo p {
  font-size: 14px;
  margin-bottom: 24px;
}

.pricing__container-features {
  margin: 16px 0 32px;
  list-style: none;
}

.pricing__container-features li {
  margin-bottom: 10px;
}

.pricing__container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
}

@media screen and (max-width: 960px) {
  .pricing__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pricing__container-card {
    width: 90%;
  }

  .pricing__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing__container-card:nth-child(2) {
    -webkit-transform: scale(1);
            transform: scale(1);
    background: #242424;
    margin: 24px;
  }

  .pricing__container-card:hover {
    -webkit-transform: none;
            transform: none;
  }
}

.services-list{
    margin-bottom: -12px;
    margin-top: 58px;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
  }

  .top-img-sec{
    align-items: center;
    margin-left: 23%;
    margin-top: -5%;
    margin-bottom: -5%;
    width: 7%;
  }
  
  .head-module{
    text-align: center;
    font-size: 45px;
    margin-bottom: 30px;
    color: #1c2237;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  
  }

  .head-sub-module{
    text-align: center;
    font-size: 33px;
    color: #1c2237;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-top: 16%;
    margin-bottom: 0%;
  }

  .services{
    margin-left: 20px;
  }
  
  
  .service{

    font-size: 20px;
    margin-left: 60%;
    margin-right: 0%;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    margin-top: 25px;
    margin-bottom: 53px
  
  }
 .service-img{
  
      margin-top: 20px;
      width: 75px;
      height: 56px;
      margin-left: -18%;
      margin-bottom: -15%;
  
    }
    .service-head{
      font-size: 26px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-bottom: 10px;
      font-weight: 600;
     
  
    }
    .service-description{
  
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-bottom: 40px;
      font-weight: 400;
      margin-left: 1%;
      margin-right: 25%;
      margin-top: 10px;
  
    }
    
    .srv-imge{
      width: 35%;
      margin-top: -47%;
      margin-left: 10%;
    }

    @media screen and (max-width: 1200px) {
  

        .service{
          font-size: 5px;
          margin-left: 61%;
          margin-right: -4%;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-top: 25px;
          margin-bottom: 53px;
      
        }
        
        .srv-imge{
          width: 40%;
          margin-top: -60%;
          margin-left: 10%;
        }
        .top-img-sec{
          align-items: center;
          margin-left: 20%;
          margin-top: -5%;
          margin-bottom: -5%;
          width: 7%;
        
        }
    
  }
  
  @media screen and (max-width: 1000px) {
  
      
      
      
    .service{
      font-size: 5px;
      margin-left: 55%;
      margin-right: -5%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    
    .srv-imge{
      width: 40%;
      margin-top: -70%;
      margin-left: 6%;
    }
    .top-img-sec{
      align-items: center;
      margin-left: 12%;
      margin-top: -5%;
      margin-bottom: -5%;
      width: 10%;
    
    }
  
  }

  @media screen and (max-width: 850px) {
    
      
    .service{
      font-size: 5px;
      margin-left: 55%;
      margin-right: -5%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    
    .srv-imge{
      width: 50%;
      margin-top: -220%;
      margin-left: 25%;
    }
    .services{
      margin-left: -25%;
      margin-right: 17%;
      margin-top: 62%;
    }
    .top-img-sec{
      align-items: center;
      margin-left: 6%;
      margin-top: -5%;
      margin-bottom: -6%;
      width: 10%;
    
    }
  
  }

  @media screen and (max-width: 760px) {
   
    .head-module{
      text-align: center;
      font-size: 40px;
      margin-left: -20%;
      margin-bottom: 30px;
      color: #1c2237;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    
    }
    
    .service{
      font-size: 5px;
      margin-left: 44%;
      margin-right: -8%;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      margin-top: 25px;
      margin-bottom: 53px;
  
    }
    .top-img-sec{
      align-items: center;
      margin-left: 0%;
      margin-top: 0%;
      margin-bottom: -5%;
      width: 10%;
    
    }
    
    .srv-imge{
      width: 52%;
      margin-top: -230%;
      margin-left: 12%;
    }
    .services{
      margin-left: -25%;
      margin-right: 17%;
      margin-top: 60%;
    }

   } 

   @media screen and (max-width: 680px) {
  
      .srv-imge{
        width: 55%;
        margin-top: -268%;
        margin-left: 12%;
      }
      .services{
        margin-left: -25%;
        margin-right: 17%;
        margin-top: 70%;
      }
      .head-module{
        margin-left: -15%;
      }
      .top-img-sec{
        align-items: center;
        margin-left: -5%;
        margin-top: -5%;
        margin-bottom: -7%;
        width: 12%;
      
      }
  
  
    }

    @media screen and (max-width: 600px) {
  
      .srv-imge{
        width: 65%;
        margin-top: -285%;
        margin-left: 10%;
      }
      .services{
        margin-left: -25%;
        margin-right: 17%;
        margin-top: 77%;
      }
      .head-module{
        margin-left: -10%;
      }
      .service{
        font-size: 5px;
        margin-left: 45%;
        margin-right: -23%;
        font-family: 'Poppins', sans-serif;
        color: #1c2237;
        margin-top: 25px;
        margin-bottom: 53px;
    
      }
      .top-img-sec{
        align-items: center;
        margin-left: -5%;
        margin-top: -5%;
        margin-bottom: -7%;
        width: 12%;
      
      }
  
    }

    @media screen and (max-width: 560px) {
  
        .srv-imge{
          width: 60%;
          margin-top: -315%;
          margin-left: 15%;
        }
        .services{
          margin-left: -25%;
          margin-right: 17%;
          margin-top: 77%;
        }
        .head-module{
          font-size: 35px;
          margin-left: -5%;
        }
        .top-img-sec{
          align-items: center;
          margin-left: -7%;
          margin-top: -7%;
          margin-bottom: -7%;
          width: 15%;
        
        }
      }
      @media screen and (max-width: 432px) {
        .srv-imge {
          width: 60%;
          margin-top: -400%;
          margin-left: 15%;
      }
      }

      @media screen and (max-width: 420px) {
        .services-list{
          width: 65%;
        }
  
        .srv-imge{
          width: 95%;
          margin-top: -490%;
          margin-left: 0%;
        }
        .services{
          margin-left: -40%;
          margin-right: 8%;
          margin-top: 100%;
        }
        .head-module{
          font-size: 30px;
          margin-left: -30%;
          margin-right: -20%;
        }
        
        .service{
          font-size: 5px;
         margin-left: 52%;
         margin-right: -25%;
         font-family: 'Poppins', sans-serif;
          color: #1c2237;
         margin-top: 25px;
         margin-bottom: 53px;
      
        }
        .top-img-sec{
          align-items: center;
          margin-left: -38%;
          margin-top: -10%;
          margin-bottom: -11%;
          width: 25%;
        
        }
        .service-head{
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-bottom: 10px;
          font-weight: 600;
         margin-left: -5%;
      
        }
        .service-description{
  
          font-size: 10px;
          font-family: 'Poppins', sans-serif;
          color: #1c2237;
          margin-bottom: 40px;
          font-weight: 400;
          margin-left: -5%;
          margin-right: 10%;
          margin-top: 10px;
      
        }
        .service-img{
  
          margin-top: 22px;
          width: 50px;
          height: 50px;
          margin-left: -35%;
          margin-bottom: -30%;
      
        }
      }
      @media screen and (max-width: 380px) {
        .srv-imge {
          width: 95%;
          margin-top: -550%;
          margin-left: 0%;
        }
      }
.sub-section{
    
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  .service-main-head{
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 0%;
    margin-top: -46%;
    margin-bottom: 0%;
      .green-text{
        color: #25ce4a;
      }
    }
    .bg-view{
     
       
      width: 100%;
      height: 100%;
      margin-left: 0%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -40px;
      max-width: 100%;
      height: auto;
  
     
     
    }
    .full-section {
      width: 100%;
      margin-top: 0%;
      margin-bottom: 30%;
  }
  
  
  .button {
      position: relative;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      padding-block: 0.5rem;
      padding-inline: 1.25rem;
      background-color: #25ce4a;
      border-radius: 9999px;
      display: flex;
      align-items: center;
      height: 45px;
      width: 190px;
      justify-content: center;
      color: #ffff;
      grid-gap: 10px;
      grid-gap: 10px;
      grid-gap: 10px;
      gap: 10px;
      font-weight: bold;
      border: 3px solid #ffffff4d;
      outline: none;
      overflow: hidden;
      font-size: 15px;
      margin-left: 10%;
      margin-top: 70px;
    }
    
    .icon {
      width: 24px;
      height: 24px;
      transition: all 0.3s ease-in-out;
    }
    
    .button:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      border-color: #fff9;
    }
    
    .button:hover .icon {
      -webkit-transform: translate(4px);
              transform: translate(4px);
    }
    
    .button:hover::before {
      -webkit-animation: shine 1.5s ease-out infinite;
              animation: shine 1.5s ease-out infinite;
    }
    
    .button::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -100px;
      opacity: 0.6;
    }
    
    @-webkit-keyframes shine {
      0% {
        left: -100px;
      }
    
      60% {
        left: 100%;
      }
    
      to {
        left: 100%;
      }
    }
    
    @keyframes shine {
      0% {
        left: -100px;
      }
    
      60% {
        left: 100%;
      }
    
      to {
        left: 100%;
      }
    }
  
    
  @media screen and (max-width: 1400px) {
  
    
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -35px;
  }
       
  }
  @media screen and (max-width: 1300px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 32%;
      margin-bottom: -55px;
  }
  }
  @media screen and (max-width: 1280px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 34%;
      margin-bottom: -70px;
  }
  }
  @media screen and (max-width: 1260px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 30%;
      margin-bottom: -70px;
  }
  }
  
  
  @media screen and (max-width: 1250px) {
  
    
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -30px;
  }
  }
  @media screen and (max-width: 1230px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 31%;
      margin-bottom: -45px;
  }
  }
  @media screen and (max-width: 1225px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -55px;
  }
  }
  @media screen and (max-width: 1200px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -115px;
  }
  .service-main-head{
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
    color: #1c2237;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 0%;
    margin-top: -40%;
    margin-bottom: 0%;
      .green-text{
        color: #25ce4a;
      }
    }
  }
  @media screen and (max-width: 1170px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 34%;
      margin-bottom: -130px;
  }
  
  }
  @media screen and (max-width: 1150px) {
    
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -145px;
  }
   
  }
  @media screen and (max-width: 1120px) {
  
    .service-main-head{
      font-size: 45px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 10%;
      margin-right: 0%;
      margin-top: -40%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 35%;
      margin-bottom: -135px;
  }
  }
  @media screen and (max-width: 1070px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 45%;
      margin-bottom: -150px;
  }
  }
  
  @media screen and (max-width: 1030px) {
  
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -165px;
  }
  
    
  }
  @media screen and (max-width: 1000px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -185px;
  }
  }
  @media screen and (max-width: 970px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 940px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 50%;
      margin-bottom: -150px;
  }
  }
  
  
  @media screen and (max-width: 950px) {
  
    .form-section{
      margin: auto;
      margin-left: 0%;
      margin-right: 0%;
      margin-top: -30%;
  
    }
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 55%;
      margin-bottom: -145px;
  }
     
  }
  @media screen and (max-width: 920px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 58%;
      margin-bottom: -165px;
  }
  }
  @media screen and (max-width: 890px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 860px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -205px;
  }
  }
  @media screen and (max-width: 850px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -160px;
  }
  }
  @media screen and (max-width: 830px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -170px;
  }
  }
  @media screen and (max-width: 800px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 65%;
      margin-bottom: -190px;
  }
  }
  @media screen and (max-width: 785px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 70%;
      margin-bottom: -200px;
  }
  }
  @media screen and (max-width: 770px) {
  
   
    .bg-view {
      width: 90%;
      height: 100%;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: 60%;
      margin-bottom: -212px;
  }
  }
  @media screen and (max-width: 760px) {
  
    .service-main-head{
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 15%;
      margin-right: 0%;
      margin-top: 10%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
     
    
      .bg-view {
           width: 100%;
           height: 100%;
           margin-left: 0%;
           margin-right: 0%;
           margin-top: 30%;
           margin-bottom: -100rem;
    }
  
     .full-section{
      width:  100%;
      margin-left: 10%;
    }
    
    
    .button {
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        padding-block: 0.5rem;
        padding-inline: 1.25rem;
        background-color: #25ce4a;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        height: 45px;
        width: 180px;
        justify-content: center;
        color: #ffff;
        grid-gap: 10px;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 15px;
        margin-left: 15%;
        margin-top: 20px;
      }
     
    
  }
  @media screen and (max-width: 670px) {
  
    .bg-view{
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: 23%;
        margin-bottom: -100rem;
    }
  }
  @media screen and (max-width: 640px) {
  
    .bg-view{
        
      margin-top: 17%;
      margin-bottom: -100rem;
    }
  }
  
  @media screen and (max-width: 560px) {
  
    .service-main-head{
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      color: #1c2237;
      font-weight: 600;
      margin-left: 15%;
      margin-right: 0%;
      margin-top: 10%;
      margin-bottom: 0%;
        .green-text{
          color: #25ce4a;
        }
      }
      
    
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: 25%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: 0%;
  
    }
    
    
    .button {
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        padding-block: 0.5rem;
        padding-inline: 1.25rem;
        background-color: #25ce4a;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        height: 45px;
        width: 180px;
        justify-content: center;
        color: #ffff;
        grid-gap: 10px;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 15px;
        margin-left: 15%;
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 450px) {
      .service-main-head{
        font-size: 29px;
        font-family: 'Poppins', sans-serif;
        color: #1c2237;
        font-weight: 600;
        margin-left: 15%;
        margin-right: 0%;
        margin-top: 10%;
        margin-bottom: 0%;
          .green-text{
            color: #25ce4a;
          }
        }
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: -62%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: -15%;
  
    }
      
    }
    @media screen and (max-width: 365px) {
      .bg-view {
        width: 100%;
        height: 100%;
        margin-left: 1%;
        margin-right: 0%;
        margin-top: -80%;
        margin-bottom: -100rem;
    }
      .full-section{
      width:  100%;
      margin-left: -20%;
  
    }
      
    }
.form-1 {
    width: 290px;
    height: 410px;
    background: #ffff;
    border-radius: 30px;
    box-shadow: 0 0 40px -10px #000;
    margin: calc(12vh - 220px) auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    position: relative;
    margin-bottom: -12.5%;
    margin-top: -35%;
    margin-left: 64%;
    }
    .input-box{
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background: none;
      outline: none;
      resize: none;
      border: 0;
      font-family: "Montserrat", sans-serif;
      transition: all 0.3s;
      border-bottom: 2px solid #bebed2;
      
    }
    .input-box:focus {
      border-bottom: 2px solid #78788c;
    }
    .div-form{
      content: "Hi";
      position: absolute;
      bottom: -15px;
      right: -20px;
      background: #25ce4a;
      color: black;
      width: 224px;
      padding: 19px 4px 10px 0;
      border-radius: 6px;
      padding-top: 10px;
      font-size: 15px;
      box-shadow: 10px 10px 40px -14px #000;
    }
    .span-form{
      margin: 0 5px 0 15px;
    }
   .button-form{
    float: right;
    padding: 8px 12px;
    margin: 8px 0 0;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #bebed2;
    background: 0;
    color: #1c2237;
    cursor: pointer;
    transition: all 0.3s;
    
   }
   .button-form:hover {
    background: #25ce4a;
    color: #1c2237;
  }
  .p-form:before {
    content: attr(type);
    display: block;
    margin: 28px 0 0;
    font-size: 14px;
    color: #5a5a5a;
  }
  .head-2{
    
      margin: 10px 0;
      padding-bottom: 10px;
      width: 180px;
      color: #1c2237;
      border-bottom: 3px solid #78788c;
   
  }
  
  
  /*Responsive */
  
  @media screen and (max-width: 1400px) {
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -13%;
      margin-left: 64%;
    }
  
       
  }
  
  
  @media screen and (max-width: 1250px) {
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -17.5%;
      margin-left: 64%;
    }
  
  
  }
  
  
  
  @media screen and (max-width: 1150px) {
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -39%;
      margin-bottom: -13.5%;
      margin-left: 64%;
    }
  
  
   
  }
  @media screen and (max-width: 1120px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 1050px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -37.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 950px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -45.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
    }
  
  }
  @media screen and (max-width: 850px) {
  
   
  
    .form-1 {
     
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -55%;
      margin-bottom: -15%;
      margin-left: 58%;
    }
  
  }
  
  
  
  @media screen and (max-width: 760px) {
  
  
      .form-1 {
     
        width: 290px;
        height: 410px;
        background: #ffff;
        border-radius: 30px;
        box-shadow: 0 0 40px -10px #000;
        margin: calc(12vh - 220px) auto;
        padding: 20px 30px;
        max-width: calc(100vw - 40px);
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        position: relative;
        margin-top: 5%;
        margin-bottom: 23%;
        margin-left: 15%;
      }
    
     
     
    
  }
  
  @media screen and (max-width: 560px) {
  
   
      .form-1 {
     
        width: 290px;
        height: 410px;
        background: #ffff;
        border-radius: 30px;
        box-shadow: 0 0 40px -10px #000;
        margin: calc(12vh - 220px) auto;
        padding: 20px 30px;
        max-width: calc(100vw - 40px);
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        position: relative;
        margin-top: 8%;
        margin-bottom: 30%;
        margin-left: 15%;
      }
    
  }
.form-box {
    max-width: 300px;
    background: #f1f7fe;
    overflow: hidden;
    border-radius: 16px;
    color: #010101;
    width: 290px;
    height: 410px;
    background: #ffff;
    border-radius: 30px;
    box-shadow: 0 0 40px -10px #000;
    margin: calc(12vh - 220px) auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    position: relative;
      margin-bottom: -12.5%;
      margin-top: -35%;
      margin-left: 64%;
    }
    
    .form {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px 24px 24px;
      grid-gap: 16px;
      gap: 16px;
      text-align: center;
    }
    
    /*Form text*/
    .title {
      font-weight: bold;
      font-size: 1.6rem;
      color: #1c2237;
    }
    
    .subtitle {
      font-size: 1rem;
      color: #666;
    }
    
    /*Inputs box*/
    .form-container {
      overflow: hidden;
      border-radius: 8px;
      background-color: #fff;
      margin: 1rem 0 .5rem;
      width: 100%;
    }
    
    .input-field {
      min-height: 45px;
      max-width: 150px;
      padding: 0 1rem;
      color: #fff;
      font-size: 15px;
      border: 2px solid #1c2237;;
      margin-bottom: 5px;
      border-radius: 10px;
      background-color: transparent;
    }
     /*{background-color: #e0ecfb;}
       padding: 16px;
     */
    .form-section {
    
      font-size: .85rem;
      text-align: center;
      box-shadow: rgb(0 0 0 / 8%) 0 -1px;
    }
    
    .form-section a {
      font-weight: bold;
      color: #0066ff;
      transition: color .3s ease;
    }
    
    .form-section a:hover {
      color: #005ce6;
      text-decoration: underline;
    }
    
  /*button-form*/
    .form button {
      background-color: #25ce4a;
      color: #fff;
      border: 0;
      border-radius: 24px;
      padding: 10px 16px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color .3s ease;
    }
    
    .form button:hover {
      background-color: #005ce6;
    }
  
  
  
  
    
  /*Responsive */
  
  @media screen and (max-width: 1400px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -13%;
      margin-left: 64%;
      }
  
       
  }
  
  
  @media screen and (max-width: 1250px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -17.5%;
      margin-left: 64%;
      }
  
  
  }
  
  
  
  @media screen and (max-width: 1150px) {
    
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -39%;
      margin-bottom: -13.5%;
      margin-left: 64%;
      }
  
   
  }
  @media screen and (max-width: 1120px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -35%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
  
    
  
  }
  @media screen and (max-width: 1050px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -37.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
    
  
  }
  @media screen and (max-width: 950px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -45.5%;
      margin-bottom: -15.5%;
      margin-left: 64%;
      }
      .form-section {
        margin: auto;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
    }
    
  
  }
  @media screen and (max-width: 850px) {
  
   
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: -55%;
      margin-bottom: -15%;
      margin-left: 58%;
      }
    
  
  }
  
  
  
  @media screen and (max-width: 760px) {
  
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: 5%;
      margin-bottom: 23%;
      margin-left: 15%;
      }
    
     
     
    
  }
  
  @media screen and (max-width: 560px) {
  
    .form-box {
      max-width: 300px;
      background: #f1f7fe;
      overflow: hidden;
      border-radius: 16px;
      color: #010101;
      width: 290px;
      height: 410px;
      background: #ffff;
      border-radius: 30px;
      box-shadow: 0 0 40px -10px #000;
      margin: calc(12vh - 220px) auto;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      position: relative;
      margin-top: 8%;
      margin-bottom: 30%;
      margin-left: 15%;
      }
      
    
  }

.contact-Main-page{
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    color: #1c2237;

}
.contact-text {
  
  margin-bottom: 53px;
  font-size: 48px;
    line-height: 1.1;
    font-weight: 600;

}
.contact-content{
  margin-bottom: 55px;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 600;
}
.text-center{
  color: #f00946;
  margin-top: -30px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 30px;
}


.column-left{
    margin-left: 0%;
    margin-right: 50%;
    margin-bottom: 5%;
    margin-top: 2%;
}
.contact-description{
  margin-top: 30px;
}
.contact-name-bottom{
  font-weight: 600;
  color: #25ce4a;
}
.contact-mail{
  margin-top: 20px;
}
.full-contact-section{
  margin-top: 15%;
  margin-bottom: 13%;
}

   .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 60%;
    margin-top: -40%;
    margin-bottom: 9%;
    padding: 20px;
    width: 420px;
    display: flex;
    flex-direction: column;
      }

  
  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  
  .form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .group {
    position: relative;
  }
  
  .form .group label {
    font-size: 14px;
    color: rgb(99, 102, 102);
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #f9f9f9;
    transition: all .3s ease;
  }
  
  .form .group input,
  .form .group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    outline: 0;
    width: 100%;
    background-color: transparent;
  }
  
  .form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:focus,
  .form .group textarea:focus {
    border-color: #3366cc;
  }
  
  .form .group input:focus+ label, .form .group textarea:focus +label {
    top: -10px;
    left: 10px;
    background-color: #f9f9f9;
    color: #3366cc;
    font-weight: 600;
    font-size: 14px;
  }
  
  .form .group textarea {
    resize: none;
    height: 100px;
  }
  
  .form button {
    background-color: #3366cc;
    color: #f9f9f9;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .form button:hover {
    background-color: #27408b;
  }


  
  @media screen and (max-width: 1300px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 60%;
      margin-top: -42%;
      margin-bottom: 0%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: 55%;
      margin-bottom: 5%;
      margin-top: 2%;
    }

  }

  @media screen and (max-width: 1110px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -47%;
      margin-bottom: 0%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
  
  }
  @media screen and (max-width: 950px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -50%;
      margin-bottom: 20%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
     .contact-text {
      margin-bottom: 53px;
      font-size: 40px;
      line-height: 1.1;
      font-weight: 600;
  }
   

  }
  @media screen and (max-width: 860px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -62%;
      margin-bottom: 25%;
      padding: 20px;
      width: 340px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: 55%;
      margin-bottom: 5%;
      margin-top: 2%;
    }

  }
  @media screen and (max-width: 800px) {
   

      .card-contact {
        background-color: #f9f9f9;
        border-radius: 10px;
        margin-left: 50%;
        margin-top: -70%;
        margin-bottom: 25%;
        padding: 20px;
        width: 340px;
        display: flex;
        flex-direction: column;
       }

  }

  @media screen and (max-width: 770px) {


    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 15%;
      margin-top: 10%;
      margin-bottom: 9%;
      padding: 20px;
      width: 420px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: -10%;
      margin-bottom: 5%;
      margin-top: 2%;
    }


    
    
}
  
@media screen and (max-width: 650px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 420px;
    display: flex;
    flex-direction: column;
   }
   .text-center{
    color: #f00946;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 30px;
  }

}
@media screen and (max-width: 580px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 5%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 48px;
      line-height: 1.1;
      font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 55px;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 600;
  }

}
@media screen and (max-width: 450px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -6%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 50px;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 600;
  }
  .column-left {
    margin-left: 0%;
    margin-right: 1%;
    margin-bottom: 5%;
    margin-top: 2%;
}
.text-center {
  color: #f00946;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 25px;
}

}

@media screen and (max-width: 400px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -10%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 50px;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 600;
  }
  .text-center {
    color: #f00946;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 25px;
  }

}
@media screen and (max-width: 380px) {
  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -12%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
   }
}

@media screen and (max-width: 360px) {
  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -12%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
   }
 
 
}




.about-page{
    margin-top: 0%;
    margin-bottom: 230px;
    justify-items: center;
    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
}
.about-us{

    margin-top: 1%;
    margin-bottom: -5%;
   

}
.head-about-page{
   
    font-weight: 600;
    padding-top: 100px;
    margin-bottom: 5%;
    margin-left: 10%;
    
}

.head-text-second{
    
    padding-top: 20px;
    color:#1c2237;
    font-size:  50px;
    
}
.sub-about-page{
    margin-top: 0%;

}
.content-about-page{
    margin-left: 10%;
    margin-right: 54%;
    margin-top: 40px;
    font-size: 25px;
    font-weight: 100;
}
.about-img{
    width: 45%;
    height: 10%;
    margin-left: 52%;
    position: relative;
    margin-top: -32%;
    margin-bottom: -3%;

}
.bg-view-about{

    margin-bottom: -70.5%;
}
.head-text{
   
    color: #f00946;
    margin-bottom: 20px;
}
.content-text{
    color: #1c2237;
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
}

/* Learn-more button */


.about-button {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-top: -80px;
    margin-bottom: 90px;
    align-items: center;
    border-radius: 0px;
    background: #183153;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
   }
   
   .about-button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #25ce4a;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
   }
   
   .about-button:hover::after {
    right: auto;
    left: 0;
    width: 100%;
   }
   
   .about-button span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 15px 30px;
    color: #fff;
    font-size: 100%;
    font-weight: 600;
    letter-spacing: 0.1rem;
    z-index: 20;
    transition: all 0.3s ease-in-out;
   }
   
   .about-button:hover span {
    color: #1c2237;
    -webkit-animation: scaleUp 0.3s ease-in-out;
            animation: scaleUp 0.3s ease-in-out;
   }
   
   @-webkit-keyframes scaleUp {
    0% {
     -webkit-transform: scale(1);
             transform: scale(1);
    }
   
    50% {
     -webkit-transform: scale(0.95);
             transform: scale(0.95);
    }
   
    100% {
     -webkit-transform: scale(1);
             transform: scale(1);
    }
   }
   
   @keyframes scaleUp {
    0% {
     -webkit-transform: scale(1);
             transform: scale(1);
    }
   
    50% {
     -webkit-transform: scale(0.95);
             transform: scale(0.95);
    }
   
    100% {
     -webkit-transform: scale(1);
             transform: scale(1);
    }
   }


/*Responsive*/


/* Rect-light adjustment  ( width > 950px ) */

/* Start */

@media screen and (max-width: 1420px) {
    .bg-view-about{
    
        margin-bottom: -70.5%;
    }
}

@media screen and (max-width: 1390px) {
    .bg-view-about{
    
        margin-bottom: -72.5%;
    }
}

@media screen and (max-width: 1350px) {
    .bg-view-about{
    
        margin-bottom:-74.5%;
    }
}
@media screen and (max-width: 1310px) {
    .bg-view-about{
    
        margin-bottom: -78%;
    }
}
@media screen and (max-width: 1280px) {
    .bg-view-about{
    
        margin-bottom: -79.5%;
    }
}
@media screen and (max-width: 1240px) {
    .bg-view-about{
    
        margin-bottom: -83.5%;
    }
}
@media screen and (max-width: 1225px) {
    .bg-view-about{
    
        margin-bottom: -85%;
    }
}
@media screen and (max-width: 1190px) {
    .bg-view-about{
    
        margin-bottom:-88%;
    }
}
@media screen and (max-width: 1160px) {
    .bg-view-about{
    
        margin-bottom:-90.5%;
    }
}
@media screen and (max-width: 1110px) {
    .bg-view-about{
    
        margin-bottom: -94.5%;
    }
}
@media screen and (max-width: 1098px) {
    .bg-view-about{
    
        margin-bottom:-95.5%;
    }
}

@media screen and (max-width: 1060px) {
    .bg-view-about{
    
        margin-bottom:-99.5%;
    }
}
@media screen and (max-width: 1040px) {
    .bg-view-about{
    
        margin-bottom:-107%;
    }
}
@media screen and (max-width: 1020px) {
    .bg-view-about{
    
        margin-bottom:-109%;
    }
}
@media screen and (max-width: 1000px) {
    .bg-view-about{
    
        margin-bottom:-112%;
    }
}
@media screen and (max-width: 970px) {
    .bg-view-about{
    
        margin-bottom: -115.5%;
    }
}


/* Stop*/




@media screen and (max-width: 950px) {

.about-page{
    margin-top: 0%;
    margin-bottom: 138px;
    justify-items: center;

    z-index: 1;
    width: 100%;
    max-width: 1399px;
    margin-right: auto;
    margin-left: auto;
}
.about-us{

    margin-top: 7.5%;
    margin-bottom: 14%;
   

}
.head-about-page{
   
    font-weight: 600;
    padding-top: 100px;
    margin-bottom: 5%;
    margin-left: 10%;
    
}

.head-text-second{
    
    padding-top: 20px;
    color:#1c2237;
    font-size:  40px;
    
}
.sub-about-page{
    margin-top: 0%;

}
.content-about-page{
    margin-left: 10%;
    margin-right: 54%;
    margin-top: 40px;
    font-size: 21px;
    font-weight: 100;

}
.about-img{
    width: 45%;
    height: 10%;
    margin-left: 57%;
    position: relative;
    margin-top: -38%;
    margin-bottom: 5%;

}
.bg-view-about{

    margin-bottom: -110%;
}


}

/* Rect-light adjustment  ( width < 930px ) */

/* Start */

@media screen and (max-width: 930px) {

    .bg-view-about{

    margin-bottom: -112%;
}

}

@media screen and (max-width: 923px)
{
.bg-view-about {
    margin-bottom:-113.5%;
}
}
@media screen and (max-width: 910px)
{
.bg-view-about {
    margin-bottom: -114%;
}
}

@media screen and (max-width: 900px)
{
.bg-view-about {
    margin-bottom: -116%;
}
}
@media screen and (max-width: 888px)
{
.bg-view-about {
    margin-bottom:-123%;
}
}
@media screen and (max-width: 880px)
{
.bg-view-about {
    margin-bottom: -122%;
}
}
@media screen and (max-width: 870px)
{
.bg-view-about {
    margin-bottom:-126%;
}
}

@media screen and (max-width: 850px)
{
.bg-view-about {
    margin-bottom: -129%;
}
}

@media screen and (max-width: 830px)
{
.bg-view-about {
    margin-bottom: -130%;
}

}

@media screen and (max-width: 820px)
{
.bg-view-about {
    margin-bottom:-134%;
}

}

@media screen and (max-width: 810px)
{
.bg-view-about {
    margin-bottom: -136%;
}

}


@media screen and (max-width: 802px)
{
.bg-view-about {
    margin-bottom:-136%;
}

}

/* End */



@media screen and (max-width: 800px) {



    .about-page{
        margin-top: 0%;
        margin-bottom: -80px;
        justify-items: center;
    
        z-index: 1;
        width: 100%;
        max-width: 1399px;
        margin-right: auto;
        margin-left: auto;
    }
    .about-us{
    
        margin-top: -3.5%;
        margin-bottom: 17%;
       
    
    }
    .head-about-page{
       
        font-weight: 600;
        padding-top: 100px;
        margin-bottom: 1%;
        margin-left: 10%;
        
    }
    
    .head-text-second{
        
        padding-top: 20px;
        color:#1c2237;
        font-size:  40px;
        
    }
    .sub-about-page{
        margin-top: 0%;
    
    }
    .content-about-page{
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 361px;
        font-size: 20px;
        font-weight: 100;
    
    }
    .about-img{
        width: 60%;
        height: 10%;
        margin-left: 5%;
        position: relative;
        margin-top: -90%;
        margin-bottom: 5%;
    
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 25px;
    }
    .bg-view-about{
    
        margin-bottom: -190%;
    }
    .about-button {
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: 10%;
        margin-top: 23px;
        margin-bottom: 90px;
        align-items: center;
        border-radius: 0px;
        background: #183153;
        font-family: "Montserrat", sans-serif;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: none;
       }

}


/* Rect-light adjustment  ( width < 800px ) */

/* Start */


@media screen and (max-width: 780px)
{
.bg-view-about {
    margin-bottom: -197%;
}

}
@media screen and (max-width: 769px)
{
.bg-view-about {
    margin-bottom: -192.5%;
}

}


@media screen and (max-width: 750px)
{
.bg-view-about {
    margin-bottom:-204.5%;
}

}

@media screen and (max-width: 730px)
{
.bg-view-about {
    margin-bottom:-208%;
}

}
@media screen and (max-width: 715px)
{
.bg-view-about {
    margin-bottom:-211%;
}

}

@media screen and (max-width: 700px)
{
.bg-view-about {
    margin-bottom: -217%;
}

}
@media screen and (max-width: 690px)
{
.bg-view-about {
    margin-bottom: -221%;
}

}





@media screen and (max-width: 680px) {

    .about-us{
    
        margin-top: -4.5%;
        margin-bottom: 17%;
    
    }
  
    .content-about-page{
        margin-left: 10%;
        margin-right: 16%;
        margin-top: 361px;
        font-size: 20px;
        font-weight: 100;
    
    }
    .about-img{
        width: 70%;
        height: 10%;
        margin-left: 5%;
        position: relative;
        margin-top: -110%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -224.5%;
    }



}


@media screen and (max-width: 670px)
{
.bg-view-about {
    margin-bottom: -229%;
}

}
@media screen and (max-width: 650px)
{
.bg-view-about {
    margin-bottom:-241%;
}
.about-us{
    
    margin-top: -4.5%;
    margin-bottom: 20%;
  
  }

}
@media screen and (max-width: 630px)
{
.bg-view-about {
    margin-bottom:-251%;
}

}
@media screen and (max-width: 610px)
{
.bg-view-about {
    margin-bottom: -257%;
}
.about-img{
    width: 70%;
    height: 10%;
    margin-left: 5%;
    position: relative;
    margin-top: -125%;
    margin-bottom: 5%;

}

}

@media screen and (max-width: 590px)
{
.bg-view-about {
    margin-bottom:-266%;
}

}
@media screen and (max-width: 580px)
{
.bg-view-about {
    margin-bottom: -269%;
}

}
@media screen and (max-width: 570px)
{
.bg-view-about {
    margin-bottom: -275%;
}

}
@media screen and (max-width: 560px)
{
.bg-view-about {
    margin-bottom:-280%;
}

}
@media screen and (max-width: 550px)
{
.bg-view-about {
    margin-bottom:-279%;
}

}
@media screen and (max-width: 540px)
{
.bg-view-about {
    margin-bottom:-290%;
}

}
@media screen and (max-width: 530px)
{
.bg-view-about {
    margin-bottom:-315%;
}

}
@media screen and (max-width: 525px)
{
.bg-view-about {
    margin-bottom:-297%;
}
}
@media screen and (max-width: 520px)
{
.bg-view-about {
    margin-bottom:-330%;
}

}
@media screen and (max-width: 510px)
{
.bg-view-about {
    margin-bottom:-335%;
}
.about-img {
    width: 85%;
    height: 10%;
    margin-left: 0%;
    position: relative;
    margin-top: -159%;
    margin-bottom: 5%;
}

}
@media screen and (max-width: 503px)
{
.bg-view-about {
    margin-bottom:-340%;
}

}







/* Stop */




/* Mobile Responsive */

@media screen and (max-width: 435px) {


    .about-us{
    
        margin-top: -8.5%;
        margin-bottom: 30%;
    
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 20px;
    }
  
    .content-about-page{
        margin-left: 10%;
        margin-right: 16%;
        margin-top: 400px;
        font-size: 16px;
        font-weight: 100;
    
    }
    .head-text-second {
        padding-top: 20px;
        color: #1c2237;
        font-size: 26px;
    }
    .about-img{
        width: 100%;
        height: 10%;
        margin-left: -5%;
        position: relative;
        margin-top: -185%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -387%;
    }
}
@media screen and (max-width: 420px) {
    .bg-view-about {
        margin-bottom: -413%;
    }
    .about-img{
        width: 100%;
        height: 10%;
        margin-left: -5%;
        position: relative;
        margin-top: -210%;
        margin-bottom: 5%;
    
    }
}

@media screen and (max-width: 400px) {


    .about-us{
    
        margin-top: -5.5%;
        margin-bottom: -20%;
    
    }

  
    .content-about-page{
        margin-left: 10%;
        margin-right: 13%;
        margin-top: 310px;
        font-size: 16px;
        font-weight: 100;
    
    }
 
    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -190%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -378%;
    }


}
@media screen and (max-width: 380px) {
    .bg-view-about {
        margin-bottom:-395%;
    }
    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -200%;
        margin-bottom: 5%;
    
    }  
}
@media screen and (max-width: 360px) {


    .content-about-page{
        margin-left: 10%;
        margin-right: 13%;
        margin-top: 310px;
        font-size: 16px;
        font-weight: 100;
    
    }
 
    .about-us{
    
        margin-top: -10.5%;
        margin-bottom: 30%;
    
    }

    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -220%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
           margin-bottom: -433.5%;
    }


}
@media screen and (max-width: 320px) {


    .content-about-page{
        margin-left: 10%;
        margin-right: 5%;
        margin-top: 282px;
        font-size: 13px;
        font-weight: 100;
    
    }
 
    .about-us{
    
        margin-top: -30.5%;
        margin-bottom: 30%;
    
    }

    .about-img{
        width: 95%;
        height: 10%;
        margin-left: 0%;
        position: relative;
        margin-top: -242%;
        margin-bottom: 5%;
    
    }
    .bg-view-about {
        margin-bottom: -437%;
    }
    .head-text-second {
        padding-top: 20px;
        color: #1c2237;
        font-size: 23px;
    }
    .head-text{
        color:#f00946;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .about-button {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-top: -19px;
    margin-bottom: 90px;
    align-items: center;
    border-radius: 0px;
    background: #183153;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
}
 


}






  .cards{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;   
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 29px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 10%;
   margin-right: 15%;
  }   
  .card1{
   width: 190px;
   height: 254px;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 190px;
   height: 254px;
   margin-left: 10%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card2-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}

@media screen and (max-width: 1150px) {

   
  .cards{
   z-index: 1;
   width: 100%;
   height: 50rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
    display: flex;
    width: 100%;
    height: 24rem;
    margin-top: 150px;
    background-color: #1c2237;
        
        
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 25px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: 20%;
   margin-right: 20%;
  }   

  .card1{
   width: 200px;
   height: 235px;
   
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 200px;
   height: 235px;
   margin-left: 5%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 110px;
     }
     .card2-img{
        width: 100%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 39%;
    margin-top: -2%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

@media screen and (max-width: 800px) {

      
  .cards{
   z-index: 1;
   width: 100%;
   height: 120rem;
   margin-top: 95px;
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   justify-content: center;
   background-color: #1c2237;

  }
  .cards-section{
   z-index: 1;
   display: grid;
   width: 100%;
   height: 70rem;
   margin-left: 0%;
   margin-top: 30px;
   
        
        
  }
  .full-items{
   z-index: 1;
   width: 100%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
   padding-right: 50px;
   padding-left: 50px;

  }
  .head-card-module{
      
   text-align: center;
   font-size: 23px;
   padding-top: 10%;
   color: #fff;
   font-weight: 600;
   margin-left: -5%;
   margin-right: 5%;
  }   

  .card1{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card2{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
.card3{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
   
}
.card4{
   width: 260px;
   height: 290px;
   margin-left: 25%;
   margin-top: 15%;
   border-radius: 1rem;
   background-color: #ffff;
   text-align: center;
}
     .card2-name{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: black;
        margin-top: 145px;
     }
     .card2-img{
        width: 90%;
        margin-top: 15%;
        margin-bottom: -40%;
     }
.card{    
   width: 20%;
   max-width: 1300px;
   margin-right: auto;
   margin-left: auto;
}
.second-button {
   position: relative;
    display: flex;
    justify-content: center;
    margin-left: 32%;
    margin-top: 80%;
    background-color: #276afb;
    align-items: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
  }

}
@media screen and (max-width: 650px) {

   .cards-section{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px;           
     }
     .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 30%;
       margin-top: 90%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }
     .cards{
      height: 115rem;
     }
     .card1{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4{
      width: 260px;
      height: 290px;
      margin-left: 10%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
}

@media screen and (max-width: 450px) {

   .cards{
      z-index: 1;
      width: 100%;
      height: 90rem;
      margin-top: 95px;
      margin-right: auto;
      margin-left: auto;
      align-items: center;
      justify-content: center;
      background-color: #1c2237;
   
     }
     .cards-section{
      z-index: 1;
      display: grid;
      width: 100%;
      height: 70rem;
      margin-left: 10%;
      margin-top: 30px; 
     }
     .full-items{
      z-index: 1;
      width: 100%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 50px;
      padding-left: 50px;
   
     }
     .head-card-module{
         
      text-align: center;
    font-size: 18px;
    padding-top: 15%;
    color: #fff;
    font-weight: 600;
    margin-left: -15%;
    margin-right: -15%;
     }   
   
     .card1{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 18%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card2{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
   .card3{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
      
   }
   .card4{
      width: 220px;
      height: 250px;
      margin-left: 3%;
      margin-top: 15%;
      border-radius: 1rem;
      background-color: #ffff;
      text-align: center;
   }
        .card2-name{
           font-size: 21px;
           font-weight: 600;
           text-align: center;
           font-family: 'Poppins', sans-serif;
           color: black;
           margin-top: 115px;
        }
        .card2-img{
           width: 90%;
           margin-top: 15%;
           margin-bottom: -40%;
        }
   .card{    
      width: 20%;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
   }
   .second-button {
      position: relative;
       display: flex;
       justify-content: center;
       margin-left: 20%;
       margin-top: 40%;
       background-color: #276afb;
       align-items: center;
       border-radius: 5px;
       font-family: "Montserrat", sans-serif;
       box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
       overflow: hidden;
       border: none;
     }

}

  .second-button:after {
   content: " ";
   width: 0%;
   height: 100%;
   background: #25ce4a;
   position: absolute;
   transition: all 0.4s ease-in-out;
   right: 0;
  }
  
  .second-button:hover::after {
   right: auto;
   left: 0;
   width: 100%;
  }
  
  .second-button span {
   text-align: center;
   text-decoration: none;
   width: 100%;
   padding: 15px 30px;
   color: #fff;
   font-size: 100%;
   font-weight: 600;
   letter-spacing: 0.1rem;
   z-index: 20;
   transition: all 0.3s ease-in-out;
  }
  
  .second-button:hover span {
   color: #1c2237;
   -webkit-animation: scaleUp 0.3s ease-in-out;
           animation: scaleUp 0.3s ease-in-out;
  }
  
  @-webkit-keyframes scaleUp {
   0% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  
   50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
   }
  
   100% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  }
  
  @keyframes scaleUp {
   0% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  
   50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
   }
  
   100% {
    -webkit-transform: scale(1);
            transform: scale(1);
   }
  }


.Scrollbutton {
    background-color:#1564ed ;
     box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
     position: fixed;
     bottom: 15px;
     right: 20px;
     width: 45px;
     height: 45px;
     border-radius: 25%;
     font-size: 30px;
     text-align: center;
     cursor: pointer;
     transition: all 0.3s;
     z-index: 9999;
     }
     
     .svgIcon {
       width: 15px;
       transition-duration: 0.3s;
     }
     
     .svgIcon path {
       fill: white;
     }
     .Scrollbutton:hover {
        
     background-color: #f00946;
       
   }
   
    
   
   @media screen and (max-width: 420px) {
   
       .Scrollbutton {
         background-color: #00bfa5;
         box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
         position: fixed;
         bottom: 1%;
         right: 20px;
         width: 45px;
         height: 45px;
         border-radius: 25%;
         font-size: 30px;
         text-align: center;
         cursor: pointer;
         transition: all 0.3s;
         z-index: 9999;
        }
       
   }
