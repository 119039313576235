
.contact-Main-page{
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    color: #1c2237;

}
.contact-text {
  
  margin-bottom: 53px;
  font-size: 48px;
    line-height: 1.1;
    font-weight: 600;

}
.contact-content{
  margin-bottom: 55px;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 600;
}
.text-center{
  color: #f00946;
  margin-top: -30px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 30px;
}


.column-left{
    margin-left: 0%;
    margin-right: 50%;
    margin-bottom: 5%;
    margin-top: 2%;
}
.contact-description{
  margin-top: 30px;
}
.contact-name-bottom{
  font-weight: 600;
  color: #25ce4a;
}
.contact-mail{
  margin-top: 20px;
}
.full-contact-section{
  margin-top: 15%;
  margin-bottom: 13%;
}

   .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 60%;
    margin-top: -40%;
    margin-bottom: 9%;
    padding: 20px;
    width: 420px;
    display: flex;
    flex-direction: column;
      }

  
  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  
  .form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .group {
    position: relative;
  }
  
  .form .group label {
    font-size: 14px;
    color: rgb(99, 102, 102);
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #f9f9f9;
    transition: all .3s ease;
  }
  
  .form .group input,
  .form .group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    outline: 0;
    width: 100%;
    background-color: transparent;
  }
  
  .form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:focus,
  .form .group textarea:focus {
    border-color: #3366cc;
  }
  
  .form .group input:focus+ label, .form .group textarea:focus +label {
    top: -10px;
    left: 10px;
    background-color: #f9f9f9;
    color: #3366cc;
    font-weight: 600;
    font-size: 14px;
  }
  
  .form .group textarea {
    resize: none;
    height: 100px;
  }
  
  .form button {
    background-color: #3366cc;
    color: #f9f9f9;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .form button:hover {
    background-color: #27408b;
  }


  
  @media screen and (max-width: 1300px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 60%;
      margin-top: -42%;
      margin-bottom: 0%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: 55%;
      margin-bottom: 5%;
      margin-top: 2%;
    }

  }

  @media screen and (max-width: 1110px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -47%;
      margin-bottom: 0%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
  
  }
  @media screen and (max-width: 950px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -50%;
      margin-bottom: 20%;
      padding: 20px;
      width: 360px;
      display: flex;
      flex-direction: column;
     }
     .contact-text {
      margin-bottom: 53px;
      font-size: 40px;
      line-height: 1.1;
      font-weight: 600;
  }
   

  }
  @media screen and (max-width: 860px) {

    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 58%;
      margin-top: -62%;
      margin-bottom: 25%;
      padding: 20px;
      width: 340px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: 55%;
      margin-bottom: 5%;
      margin-top: 2%;
    }

  }
  @media screen and (max-width: 800px) {
   

      .card-contact {
        background-color: #f9f9f9;
        border-radius: 10px;
        margin-left: 50%;
        margin-top: -70%;
        margin-bottom: 25%;
        padding: 20px;
        width: 340px;
        display: flex;
        flex-direction: column;
       }

  }

  @media screen and (max-width: 770px) {


    .card-contact {
      background-color: #f9f9f9;
      border-radius: 10px;
      margin-left: 15%;
      margin-top: 10%;
      margin-bottom: 9%;
      padding: 20px;
      width: 420px;
      display: flex;
      flex-direction: column;
     }
     .column-left{
      margin-left: 0%;
      margin-right: -10%;
      margin-bottom: 5%;
      margin-top: 2%;
    }


    
    
}
  
@media screen and (max-width: 650px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 420px;
    display: flex;
    flex-direction: column;
   }
   .text-center{
    color: #f00946;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 30px;
  }

}
@media screen and (max-width: 580px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 5%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 48px;
      line-height: 1.1;
      font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 55px;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 600;
  }

}
@media screen and (max-width: 450px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -6%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 50px;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 600;
  }
  .column-left {
    margin-left: 0%;
    margin-right: 1%;
    margin-bottom: 5%;
    margin-top: 2%;
}
.text-center {
  color: #f00946;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 25px;
}

}

@media screen and (max-width: 400px) {


  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -10%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
   }
   .contact-text {
  
    margin-bottom: 53px;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 600;
  
  }
  .contact-content{
    margin-bottom: 50px;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 600;
  }
  .text-center {
    color: #f00946;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 25px;
  }

}
@media screen and (max-width: 380px) {
  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -12%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
   }
}

@media screen and (max-width: 360px) {
  .card-contact {
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: -12%;
    margin-top: 10%;
    margin-bottom: 9%;
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
   }
 
 
}



