.content-page{
   
    background-color: white;
    height: 350px;
    align-items: center;
    text-align: center;
}
.content-heading{
    text-align: center;
    font-size: 48px;
    line-height: 56px;
    margin-top: 34PX;
    margin-bottom: -15px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 21px;
   
}
.content-para{
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    margin-top: 38PX;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 200px;
    margin-right: 206px;

}
.content-btn{

    margin-top: 45px;
    color: white;
    background-color: #276afb;;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    

   
  
    &:hover{
        background-color: #f00946;
        border: 2px solid black;
        color: white;
    }
}
@media screen and (max-width: 950px ) {
    .content-page{
   
        background-color: white;
        height: 300px;
        align-items: center;
    }

    .content-heading{
        text-align: center;
        font-size: 33px;
        line-height: 56px;
        margin-top: 20PX;
        margin-bottom: 16px;
        color: black;
        font-family: 'Poppins', sans-serif;
       
    }
    .content-para{
        text-align: center;
        font-size: 17px;
        line-height: 22px;
        margin-top: 21px;
        color: black;
        font-family: 'Poppins', sans-serif;
        margin-left: 15px;
        margin-right: 15px;
       
    
    }
    .content-btn{
        
        margin-top: 44PX;
        color: white;
        background-color: #276afb;
        border: 1px solid #F5F5F5;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        
      
        &:hover{
            background-color: #f00946;
            border: 2px solid black;
            color: white;
        }


    }
}