.chat-button{
    position: fixed;
    bottom: 15px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #00bfa5;
    color: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
}

@media screen and (max-width: 420px) {

    .chat-button{
        position: fixed;
        bottom: 1%;
        right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #00bfa5;
        color: #fff;
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 9999;
    }
    
}